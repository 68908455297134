@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-information {
  @include mi.mq(sp){
    padding-bottom: 16px;
    margin: {
      //right: -16px;
      //left: -16px;
    }
  }

  .c-list {
    display: flex;
    flex-direction: column;

    .c-scope {
      display: flex;
      border-bottom: 1px solid black;
      line-height: 1.6;
      padding: 16px 0;
      font-weight: bold;

      @include mi.mq(){
        font-size: 1.8rem;
        align-items: baseline;
      }

      @include mi.mq(sp){
        flex-direction: column;
        gap: 8px;
        font-size: 1.4rem;
      }

      + .c-scope {
        margin-top: 10px;
      }

      dt {
        @include mi.mq(){
          width: 112px;
          margin-right: 16px;
          flex-shrink: 0;
        }

        @include mi.mq(sp){
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 8px;
        }

        time {
          @include mi.mq(sp){
            width: 80px;
            flex-shrink: 0;
          }
        }
      }

      dd {
        @include mi.mq(){
          gap: 18px;
          display: flex;
          align-items: baseline;
        }

        a {
          @include mi.mq(){
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &[data-information-type="press_release"] {
        dt {
          @include mi.mq(sp){
            &::after {
              content: "プレスリリース";
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background: var(--textColor_secondary);
              white-space: nowrap;
              border-radius: 50px;
              color: white;
              font-size: 1.2rem;
              height: 24px;
              padding: 0 8px;
            }
          }
        }

        dd {
          @include mi.mq(){
            &::before {
              content: "プレスリリース";
              flex-shrink: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              background: var(--textColor_secondary);
              white-space: nowrap;
              border-radius: 50px;
              color: white;
              font-size: 1.2rem;
              height: 24px;
              padding: 0 8px;
            }
          }
        }
      }
    }
  }
}