@use "../extension/mixin" as mi;
@use "../extension/variables" as *;

.l-footer {
  @include mi.mq(){
    margin-top: 80px;
  }

  @include mi.mq(sp){
    margin-top: 40px;
  }

  html[lang="en-US"] & {
    margin-top: 0;
  }
}