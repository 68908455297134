@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-shop_detail {
  @include mi.mq(){
    max-width: 1400px;
    min-width: 1152px;
    width: 100%;
    margin: {
      right: auto;
      left: auto;
      bottom: 64px;
    }
    padding: {
      top: 64px;
      right: 64px;
      left: 64px;
    }
  }

  @include mi.mq(sp){
    padding: {
      top: 32px;
    }

    html[lang="en-US"] & {
      @include mi.mq(sp){
        padding-bottom: 24px;
      }
    }
  }

  &__main {
    @include mi.mq(){
      display: flex;
      gap: 64px;
    }
  }

  &__img {
    @include mi.mq(){
      width: 100%;
    }

    img {

      width: 100%;
      height: auto;

      @include mi.mq(){
        border-radius: 24px;
      }

      @include mi.mq(sp){
        border-radius: 16px;
      }
    }
  }

  &__container {
    width: 100%;
  }

  &__symbol {
    @include mi.mq(){
      display: flex;
      justify-content: center;
      padding-bottom: 32px;
    }

    @include mi.mq(sp){
      margin-top: 32px;
    }

    img {
      @include mi.mq(){
        width: auto;
        height: 198px;
      }

      @include mi.mq(sp){
        width: 100%;
        height: auto;
      }
    }
  }

  &__info {
    @include mi.mq(){
    }

    @include mi.mq(sp){
      margin-top: 12px;
      padding: {
        right: 16px;
        left: 16px;
      }
    }

    .c-detail {
      display: flex;
      flex-direction: column;

      .c-scope {
        border-bottom: 1px dotted var(--textColor_secondary);
        display: flex;
        align-items: flex-start;
        padding: 0 0 15px;

        + .c-scope {
          padding: 15px 0;
        }

        dt {
          width: 80px;
          line-height: 1.6;
          font-weight: bold;
          flex-shrink: 0;

          @include mi.mq(){
            font-size: 1.6rem;
          }

          @include mi.mq(sp){
            @include mi.mb_font_size(14);
          }

          html[lang="en-US"] & {
            width: 100px;
          }
        }

        dd {
          line-height: 1.6;
          @include mi.font-secondary;

          @include mi.mq(){
            font-size: 1.6rem;
          }

          @include mi.mq(sp){
            @include mi.mb_font_size(14);
          }

          a {
            text-decoration: underline;
            word-break: break-all;

            @include mi.mq(){
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .c-social {
      display: flex;
      align-items: center;
      gap: 30px;

      a {
        @include mi.mq(){
          transition: .2s;

          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }

  &__tax_free {
    @include mi.mq(){
      margin-top: 4px;
      padding: 16px 0;
    }

    @include mi.mq(sp){
      margin-top: 2px;
      padding: 8px 0;
    }

    .c-label {
      display: inline-block;
      color: var(--textColor_red);
      font-weight: bold;
      border: 1px solid var(--textColor_red);
      padding: 4px 8px;

      @include mi.mq(){
        font-size: 1.6rem;
      }

      @include mi.mq(sp){
        font-size: 1.4rem;
      }
    }
  }

  &__comment {
    background: var(--bgColor_lgray);

    @include mi.mq(){
      margin-top: 40px;
      padding: 24px;
      border-radius: 24px;
    }

    @include mi.mq(sp){
      margin: {
        top: 32px;
        right: 16px;
        left: 16px;
      }
      padding: 16px;
      border-radius: 16px;
    }

    p {
      line-height: 1.8;

      @include mi.mq(){
        font-size: 1.8rem;
      }

      @include mi.mq(sp){
        @include mi.mb_font_size(16);
      }
    }
  }

  &__shop_news {
    @include mi.mq() {
      max-width: 1400px;
      //min-width: 1152px;
      width: 100%;
      margin: {
        top: 64px;
        right: auto;
        left: auto;
      }
      padding: {
      }
      display: flex;
      flex-direction: column;
      gap: 64px;
    }

    @include mi.mq(sp){
      margin-top: 32px;
    }

    .c-heading.-lv1 {
      @include mi.mq(sp){
        margin-bottom: 32px;
      }
    }
  }

  .c-btn_wrapper {
    display: flex;
    justify-content: center;

    @include mi.mq(sp){
      margin-top: 32px;
    }
  }

  &__free_space.p-page {
    @include mi.mq(){
      width: 100%;
      max-width: 1024px;
      padding: 0;
    }
  }
}