@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-home {
  &_slider {
    @include mi.mq(){
      position: relative;
      background: {
        image: url(../img/home/bg_slider.png);
        repeat: repeat-x;
        position: center top;
      }
      margin: {
        top: -10px;
        bottom: 70px;
      }
      padding-top: 60px;
    }

    @include mi.mq(sp){
      margin: {
        right: -16px;
        left: -16px;
      }

      background: {
        size: auto ;
      };
    }

    .slick-list {
      @include mi.mq(){
        overflow: unset !important;
      }
    }

    &__stage {
      @include mi.mq(sp){
        background: {
          //color: red;
          image: url(../img/home/bg_slider.png);
          repeat: repeat-x;
          position: center top;
          size: auto 100%;
        }

        padding: {
          top: 48px;
          //right: 10px;
          right: 0;
          bottom: 48px;
          //left: 10px;
          left: 0;
        }
        margin-bottom: 16px;
      }

      .slick-track {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .c-list {
        display: flex;
        justify-content: center;
        align-items: center;

        @include mi.mq(){
          height: 500px;
        }
      }

      li {
        flex-shrink: 0;

        @include mi.mq(){
          width: 416px;
          padding: 0 33px;
          transition: .3s;
        }

        @include mi.mq(sp){
          width: 100%;
          padding: 0 20px;

        }

        &.slick-center {
          @include mi.mq(){
            transform: scale(1.2, 1.2);
          }
        }
      }

      a {
        aspect-ratio: 1 / 1;
        display: flex;

        @include mi.mq(){
          &:hover {
            img {
              filter: brightness(110%);
            }
          }
        }
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;

        @include mi.mq(){
          transition: .35s;
        }
      }
    }

    &__arrows {
      width: 595px;
      //height: 58px;
      height: 0;
      position: absolute;
      top: calc(60px + 250px); // topマージン + is-current の半分
      right: 0;
      left: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      user-select: none;

      @include mi.mq(sp){
        display: none;
      }
    }

    &__arrow {
      width: 28px;
      height: 61px;
      overflow: hidden;
      text-indent: -100vw;
      background: {
        repeat: no-repeat;
        size: contain;
      }
      cursor: pointer;

      &.-prev {
        background: {
          image: url(../img/icn/icn_arrow_slider_prev.svg);
        }
      }

      &.-next {
        background: {
          image: url(../img/icn/icn_arrow_slider_next.svg);
        }
      }
    }

    &__marker {
      display: flex;
      justify-content: center;

      @include mi.mq(){
        margin-top: 70px;
      }

      .c-list {
        display: flex;
        justify-content: center;
        gap: 15px;

        li {
          width: 14px;
          height: 14px;
          background: var(--bgColor_slider_marker);
          border-radius: 14px;

          @include mi.mq(){
            cursor: pointer;
          }

          &.slick-active {
            background: var(--siteColor_main);
            pointer-events: none;
          }

          button {
            appearance: none;
            width: 100%;
            height: 100%;
            visibility: hidden;
          }
        }
      }
    }
  }

  &_event_campaign {
    @include mi.mq(){
      width: 100%;
      max-width: 1400px;
      padding: 0 64px 20px;
      margin: 70px auto;
    }

    .c-heading.-lv1 {
      @include mi.mq(){
        margin-bottom: 8px;
      }
    }

    &__date {
      display: flex;
      justify-content: center;
      font-weight: bold;
      line-height: 2;

      @include mi.mq(){
        font-size: 2rem;
        margin-bottom: 40px;
      }

      @include mi.mq(sp){
        font-size: 1.7rem;
        margin-bottom: 22px;
      }
    }

    .p-card_list {
      @include mi.mq(){
        gap: 40px;
      }

      @include mi.mq(sp){
        gap: 16px;
      }

      .p-card {
        @include mi.mq(){
          width: calc((100% - (40px * 3)) / 4);
        }

        @include mi.mq(sp){
          width: calc((100% - (16px * 1)) / 2);
        }
      }
    }

    .c-btn_wrapper {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }

  &_information {
    display: flex;
    flex-direction: column;

    @include mi.mq(){
      width: 1000px;
      margin: 102px auto;
      gap: 40px;
    }

    @include mi.mq(sp){
      margin: {
        top: 32px;
        bottom: 32px;
      }
    }

    .c-heading.-lv1 {
      @include mi.mq(){
        margin-bottom: 8px;
      }

      @include mi.mq(sp){
        margin-bottom: 4px;
      }
    }

    .c-btn_wrapper {
      display: flex;
      justify-content: center;

      @include mi.mq(){
        margin-top: 40px;
      }

      @include mi.mq(sp){
        margin-top: 16px;
      }
    }
  }

  &_kv {
    display: flex;
    flex-direction: column;

    @include mi.mq(){
      width: 950px;
      margin: {
        top: 8px;
        right: auto;
        bottom: 20px;
        left: auto;
      }
      gap: 36px;
    }

    @include mi.mq(sp){
      margin: {
        right: -16px;
        top: 8px;
        left: -16px;
        bottom: 16px;
      }
      gap: 20px;
    }

    .c-img {
      img {
        width: 100%;
        height: auto;
      }
    }

    figcaption {
      @include mi.mq(){
        font-size: 2rem;
        line-height: 2;
        text-align: center;
      }

      @include mi.mq(sp){
        font-size: 1.8rem;
        line-height: 1.6;
        text-align: center;
      }
    }
  }
}