@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-floor_map {
  @include mi.mq(){
    width: 1104px;
    margin: 0 auto;
  }

  &__img {
    @include mi.mq(){
      padding: 24px 0;
      text-align: center;

      img {
        width: 600px;
        height: auto;
      }
    }

    @include mi.mq(sp){
      padding: 16px 0;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__shop_list {
    @include mi.mq(){
      margin: {
        top: 40px;
        right: auto;
        left: auto;
      }
      width: 823px;
    }

    @include mi.mq(sp){
      margin-top: 32px;
    }

    .c-list {
      display: flex;

      @include mi.mq(){
        gap: 16px 24px;
        flex-wrap: wrap;
      }

      @include mi.mq(sp){
        flex-direction: column;
        gap: 16px;
      }

      .c-scope {
        display: flex;
        line-height: 1.5;
        font-weight: bold;

        @include mi.mq(){
          width: calc((100% - 24px) / 2);
          font-size: 1.6rem;
        }

        @include mi.mq(sp){
          font-size: 1.4rem;
        }

        dt {
          width: 2em;
          text-align: right;
          margin-right: 0.5em;
          display: inline-flex;
          align-items: flex-start;
          justify-content: flex-end;
          flex-shrink: 0;
        }

        dd {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;

          @include mi.mq(){
            gap: 4px 12px;

            a:hover {
              text-decoration: underline;
            }
          }

          @include mi.mq(sp){
            gap: 6px 4px;
            margin-bottom: -4px;
          }
        }

        &[data-tax-free="true"] {
          dd {
            &::after {
              content: "TAX FREE";
              line-height: 1;
              font-weight: bold;
              color: var(--textColor_red);
              border: 1px solid var(--textColor_red);
              padding: 3px 8px;
              transform: translateY(-1px);

              @include mi.mq(){
                font-size: 1.6rem;
              }

              @include mi.mq(sp){
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}