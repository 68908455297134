@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-card {
  &.-event {
    @include mi.mq(){
      width: 288px;
    }

    @include mi.mq(sp){
      width: calc((100% - 16px) /2 );
    }

    a {
      display: flex;
      flex-direction: column;

      @include mi.mq(){
        gap: 12px;
      }

      @include mi.mq(sp){
        gap: 8px;
      }

      &:hover {
        .c-img img {
          @include mi.mq(){
            transform: scale(1.1);
          }
        }
      }
    }

    .c-img {
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      aspect-ratio: 1 / 1;
      display: flex;

      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 4px 8px;
        font-weight: bold;

        @include mi.mq(){
          font-size: 1.4rem;
          border-radius: 8px 0 0;
        }

        @include mi.mq(sp){
          border-radius: 4px 0 0;
        }
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: .2s;
      }
    }

    .c-date {
      border-bottom: 1px solid var(--borderColor_date);

      @include mi.mq(){
        padding: 4px 0;
      }

      @include mi.mq(sp){
        padding-bottom: 2px;
      }

      time {
        font-weight: bold;

        @include mi.mq(){
          font-size: 1.4rem;
          line-height: 1.4;
        }

        @include mi.mq(sp){
          line-height: 1.5;
          @include mi.mb_font_size(12);
        }

        small {
          @include mi.mq(){
            font-size: 1.2rem;
          }

          @include mi.mq(sp){
            font-size: 1rem;
          }
        }
      }
    }

    .c-headline {
      @include mi.mq(){
        font-size: 1.4rem;
        line-height: 1.4;
        font-weight: bold;
      }

      @include mi.mq(sp){
        @include mi.mb_font_size(13);
        line-height: 1.4;
        font-weight: bold;
      }
    }

    .c-sub_text {
      color: var(--textColor_secondary);

      @include mi.mq(){
        font-size: 1.2rem;
      }

      @include mi.mq(sp){
        @include mi.mb_font_size(12);
      }
    }

    &[data-event-type="shop"] {
      .c-img {
        &::after {
          content: "SHOP NEWS";
          background: var(--bgColor_card_shop);
        }
      }
    }

    &[data-event-type="event"] {
      .c-img {
        &::after {
          content: "EVENT";
          background: var(--bgColor_card_event);
        }
      }
    }

    &[data-event-type="cooking"] {
      .c-img {
        &::after {
          content: "COOKING";
          background: var(--bgColor_card_cooking);
        }
      }
    }

    &[data-event-type="workshop"] {
      .c-img {
        &::after {
          content: "WORKSHOP";
          background: var(--bgColor_card_workshop);
        }
      }
    }

    &[data-event-type="pop-up"] {
      .c-img {
        &::after {
          content: "POP-UP";
          background: var(--bgColor_card_pop-up);
        }
      }
    }

    &[data-event-type="food"] {
      .c-img {
        &::after {
          content: "食品催事";
          background: var(--bgColor_card_food);
        }
      }
    }
  }

  &.-shop {
    display: flex;
    flex-direction: column;

    @include mi.mq(){
      padding: 16px 0;
    }

    @include mi.mq(sp){
      padding: 8px 0;
      display: grid;
      grid-template-columns: 150px 1fr;
      grid-template-rows: auto auto 1fr;
      gap: 0 16px;
      grid-template-areas:
    "header header"
    "image phone"
    "image category";
    }

    .c-header {
      @include mi.mq(){
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
        gap: 8px;
      }

      @include mi.mq(sp){
        grid-area: header;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        gap: 8px;
      }
    }

    .c-floor {
      background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: bold;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-size: 1.4rem;
    }

    .c-name {
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 1.5;
    }

    .c-img {
      aspect-ratio: 288 / 137;
      display: flex;

      @include mi.mq(){
        margin-bottom: 24px;
      }

      @include mi.mq(sp){
        grid-area: image;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: auto;
      }
    }

    .c-phone {
      font-size: 1.4rem;
      line-height: 1.8;
      font-weight: bold;
    }

    .c-category {
      font-size: 1.2rem;
      line-height: 1.8;
      color: var(--textColor_secondary);
      @include mi.font-secondary;

      @include mi.mq(){
      }

      @include mi.mq(sp){
        grid-area: category;
      }
    }

    &[data-tax-free="true"] {
      @include mi.mq(){
        &::after {
          align-self: flex-start;
          content: "TAX FREE";
          line-height: 1;
          font-weight: bold;
          color: var(--textColor_red);
          border: 1px solid var(--textColor_red);
          padding: 3px 8px;
          transform: translateY(-1px);


          @include mi.mq(){
            font-size: 1.6rem;
            margin: 16px 0;
          }
        }
      }

      .c-category {
        @include mi.mq(sp){
          &::after {
            align-self: flex-start;
            content: "TAX FREE";
            line-height: 1;
            font-weight: bold;
            color: var(--textColor_red);
            border: 1px solid var(--textColor_red);
            padding: 3px 8px;
            transform: translateY(-1px);
            display: inline-flex;
            font-size: 1.4rem;
            margin-top: 8px;
          }
        }
      }
    }
  }

  &_list {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;

    @include mi.mq(){
      gap: 40px;
    }

    @include mi.mq(sp){
      gap: 16px;
      padding: {
        right: 10px;
        left: 10px;
      }
    }
  }
}