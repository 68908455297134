@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-instagram {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mi.mq(){
    margin-top: 80px;
  }

  @include mi.mq(sp){
    margin-top: 40px;
  }

  .c-heading {
    @include mi.mq(){
      margin-bottom: 16px;
    }
  }

  &__wrapper {
    @include mi.mq(){
      width: 100%;
      max-width: 1512px;
    }

    @include mi.mq(sp){
      margin: {
        right: 16px;
        left: 16px;
      }
    }

    #sb_instagram {
      #sbi_images {
        @include mi.mq(){
          gap: 8px !important;
        }

        @include mi.mq(sp){
          gap: 4px !important;
        }

        .sbi_item {
          aspect-ratio: 1 / 1;
        }
      }
    }
  }
}