@charset "UTF-8";
:root {
  --bgColor_lgray: rgba(244,244,244,1);
  --bgColor_shop_lineup: rgba(248,245,232,1);
  --bgColor_tab_header: rgba(231,231,231,1);
  --bgColor_btn_primary: rgba(234,234,234,1);
  --bgColor_header1: rgba(218,243,226,1);
  --bgColor_sp_nav: rgba(239,239,239,1);
  --textColor_primary: rgba(0,0,0,1);
  --textColor_secondary: rgba(119,119,119,1);
  --textColor_red: rgba(232,55,55,1);
  --textColor_green: rgba(54,131,81,1);
  --siteColor_main: rgba(107,178,132,1);
  --borderColor_date: rgba(152,152,152,1);
  --borderColor_table: rgba(179,179,179,1);
  --lBlue: rgba(176,234,236,1);
  --bgColor_slider_marker: rgba(217,217,217,1);
  --txtColor_placeholder: rgba(182,182,182,1);
  --textColor_link: rgba(8,102,255,1);
  --gradation_g: linear-gradient(160deg, rgba(239,248,232,1) 0%, rgba(229,247,242,1) 100%);
  --bgColor_card_shop: rgba(176,234,236,1);
  --bgColor_card_event: rgba(176,236,189,1);
  --bgColor_card_cooking: rgba(253,217,174,1);
  --bgColor_card_workshop: rgba(245,247,182,1);
  --bgColor_card_pop-up: rgba(245,209,250,1);
  --bgColor_card_food: rgba(255,192,192,1);
}

html {
  font-size: 62.5%;
  margin-top: 0 !important;
}

html, body {
  width: 100%;
}

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: inherit;
  font-stretch: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  color: inherit;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;
}
body * {
  box-sizing: border-box;
}
body *::before, body *::after {
  box-sizing: border-box;
}

sup {
  font-size: 0.8em;
  vertical-align: super;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-spacing: 0;
}

a {
  text-decoration: none;
}

img {
  vertical-align: top;
}

*,
*::before,
*::after {
  font-family: "Shuei NijimiMGo B", sans-serif;
}

.l-wrapper {
  overflow: hidden;
  max-width: 100%;
}
@media print, screen and (min-width: 768px) {
  .l-wrapper {
    min-width: 1152px;
  }
}

@media screen and (max-width: 769px) {
  .l-main {
    padding: 0 16px;
  }
}

.l-header {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .l-header {
    padding: 16px 24px;
    justify-content: center;
  }
}
@media screen and (max-width: 769px) {
  .l-header {
    height: 64px;
    padding: 0 14px;
    align-items: center;
    position: relative;
    z-index: 100;
  }
}

@media print, screen and (min-width: 768px) {
  .l-footer {
    margin-top: 80px;
  }
}
@media screen and (max-width: 769px) {
  .l-footer {
    margin-top: 40px;
  }
}
html[lang=en-US] .l-footer {
  margin-top: 0;
}

.c-input_text input {
  appearance: none;
  border: none;
}

.c-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
}
.c-btn.-primary {
  background: var(--bgColor_btn_primary);
  line-height: 2;
  border-radius: 100px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
@media print, screen and (min-width: 768px) {
  .c-btn.-primary {
    padding: 8px 24px;
    font-size: 20px;
  }
}
@media screen and (max-width: 769px) {
  .c-btn.-primary {
    padding: 4px 16px;
    font-size: clamp(15.3599999994px, 18 / 390 * 100vw, 18px);
  }
}
@media print, screen and (min-width: 768px) {
  .c-btn.-primary:hover {
    transition: 0.2s;
  }
  .c-btn.-primary:hover:hover {
    opacity: 0.7;
  }
}
.c-btn.-primary .c-icn {
  display: inline-flex;
  align-items: center;
  margin-left: 9px;
}

.c-heading.-page_title {
  background: var(--gradation_g);
  font-weight: bold;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .c-heading.-page_title {
    padding: 32px 16px;
    font-size: 3.2rem;
    text-align: center;
    gap: 16px;
  }
}
@media screen and (max-width: 769px) {
  .c-heading.-page_title {
    padding: 24px 16px;
    gap: 8px;
    font-size: clamp(20.4799999992px, 24 / 390 * 100vw, 24px);
    margin-right: -16px;
    margin-left: -16px;
  }
}
.c-heading.-lv1 {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  font-weight: bold;
  min-height: 70px;
}
@media print, screen and (min-width: 768px) {
  .c-heading.-lv1 {
    font-size: 4rem;
  }
}
@media screen and (max-width: 769px) {
  .c-heading.-lv1 {
    font-size: 3.2rem;
  }
}
.c-heading.-lv1::before {
  content: "";
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background: var(--bgColor_header1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: -1;
}

.p-block_skip {
  position: fixed;
  top: 0;
  left: 0;
  appearance: none;
  opacity: 0;
  transform: translate(-100%, -100%);
}
body.is-debug .p-block_skip {
  appearance: auto;
  opacity: 1;
  transform: translate(100%, 100%);
  z-index: 10000;
}

@media print, screen and (min-width: 768px) {
  .p-global_header {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto auto;
    gap: 6px 0;
    grid-template-areas: "search search" "symbol global_nav";
    width: 100%;
    max-width: 1400px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_header {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.p-global_header__symbol {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-global_header__symbol {
    grid-area: symbol;
    gap: 7px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_header__symbol {
    gap: 6px;
  }
  .p-global_header__symbol img {
    height: 45px;
    width: auto;
  }
}
.p-global_header__symbol a {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-global_header__symbol a {
    gap: 7px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_header__symbol a {
    gap: 6px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-global_utility {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 22px;
    grid-area: search;
  }
}
@media screen and (max-width: 769px) {
  .p-global_utility {
    margin-left: auto;
  }
}

.p-language_switcher {
  height: 28px;
  display: flex;
  align-items: center;
}
.p-language_switcher a {
  font-size: 1.4rem;
  font-weight: bold;
}

.p-global_search {
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .p-global_search {
    height: 28px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_search {
    height: 44px;
    margin: 0 36px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_search .c-input_text input {
    width: 182px;
    height: 28px;
    border-radius: 14px;
    background: var(--bgColor_lgray);
    padding: 0 16px;
  }
  .p-global_search .c-input_text input::placeholder {
    color: var(--textColor_secondary);
  }
}
@media screen and (max-width: 769px) {
  .p-global_search .c-input_text input {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    background: white;
    padding: 0 16px;
  }
}
.p-global_search .c-btn {
  background: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 19px;
  height: 19px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-global_search .c-btn {
    right: 9px;
    width: 19px;
    height: 19px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_search .c-btn {
    right: 13px;
    width: 26px;
    height: 26px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_search .c-btn img {
    width: 19px;
    height: 19px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_search .c-btn img {
    width: 26px;
    height: 26px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-global_nav {
    grid-area: global_nav;
    display: flex;
    justify-content: flex-end;
  }
}
@media screen and (max-width: 769px) {
  .p-global_nav {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--bgColor_sp_nav);
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.11);
  }
}
@media screen and (max-width: 769px) {
  .p-global_nav__body {
    display: flex;
  }
}
.p-global_nav__body .c-list {
  display: flex;
  flex: 1;
}
@media print, screen and (min-width: 768px) {
  .p-global_nav__body .c-list {
    gap: 16px;
    padding: 3px 0;
  }
}
@media screen and (max-width: 769px) {
  .p-global_nav__body .c-list {
    padding: 9px 0;
  }
}
@media screen and (max-width: 769px) {
  .p-global_nav__body .c-list li {
    width: 100%;
  }
}
.p-global_nav__body .c-list a {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-global_nav__body .c-list a {
    height: 48px;
    padding: 0 8px;
    position: relative;
  }
  .p-global_nav__body .c-list a::after {
    content: "";
    height: 1px;
    width: 0;
    background: var(--siteColor_main);
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    transition: 0.1s;
  }
  .p-global_nav__body .c-list a.is-current::after, .p-global_nav__body .c-list a:hover::after {
    width: 100%;
  }
}
@media screen and (max-width: 769px) {
  .p-global_nav__body .c-list a {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}
.p-global_nav__body .c-list a .c-icn {
  width: clamp(20.4799999992px, 24 / 390 * 100vw, 24px);
}
@media print, screen and (min-width: 768px) {
  .p-global_nav__body .c-list a .c-icn {
    display: none;
  }
}
.p-global_nav__body .c-list a .c-icn img {
  width: 100%;
  height: auto;
}
.p-global_nav__body .c-list a .c-label {
  font-weight: bold;
  line-height: 1.5;
  word-break: keep-all;
}
@media print, screen and (min-width: 768px) {
  .p-global_nav__body .c-list a .c-label {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-global_nav__body .c-list a .c-label {
    font-size: clamp(10.2399999996px, 12 / 390 * 100vw, 12px);
    text-align: center;
  }
  html[lang=en-US] .p-global_nav__body .c-list a .c-label {
    min-height: 34px;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 769px) {
  html[lang=ja] .p-global_nav__body .c-list a .c-label.-thin {
    letter-spacing: -0.1em;
  }
}
.p-global_nav__sub {
  position: fixed;
  right: 0;
  left: 0;
  background: var(--bgColor_sp_nav);
  z-index: 2;
  padding: 24px 26px 70px;
  transition: 0.2s;
  transform: translateY(101%);
}
html[lang=en-US] .p-global_nav__sub {
  bottom: 0;
  max-height: 100dvh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
html[lang=ja] .p-global_nav__sub {
  top: 0;
  height: 100dvh;
}
@media print, screen and (min-width: 768px) {
  .p-global_nav__sub {
    display: none;
  }
}
.p-global_nav__sub .c-list.-square {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 28px;
  justify-content: center;
}
.p-global_nav__sub .c-list.-square li {
  width: calc((100% - 20px) / 3);
}
.p-global_nav__sub .c-list.-square a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  aspect-ratio: 1/1;
  background: white;
  border-radius: 6px;
}
.p-global_nav__sub .c-list.-square .c-icn {
  width: 26px;
  height: 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.p-global_nav__sub .c-list.-square .c-label {
  text-align: center;
  font-size: clamp(11.0933333329px, 13 / 390 * 100vw, 13px);
  line-height: 1.5;
  height: 40px;
  display: inline-flex;
  align-items: center;
}
.p-global_nav__sub .c-list.-thin {
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 24px;
}
.p-global_nav__sub .c-list.-thin li {
  width: calc((100% - 10px) / 2);
}
.p-global_nav__sub .c-list.-thin a {
  display: flex;
  height: 35px;
  background: white;
  border-radius: 20px;
  font-size: clamp(11.0933333329px, 13 / 390 * 100vw, 13px);
}
.p-global_nav__sub .c-close {
  position: absolute;
  right: 12px;
  bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
}
.p-global_nav__sub .c-close .c-times {
  width: 25px;
  height: 25px;
  position: relative;
}
.p-global_nav__sub .c-close .c-times::before, .p-global_nav__sub .c-close .c-times::after {
  content: "";
  width: 35px;
  height: 1px;
  background: #000;
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  left: -5px;
  margin: auto;
}
.p-global_nav__sub .c-close .c-times::before {
  transform: rotate(45deg);
}
.p-global_nav__sub .c-close .c-times::after {
  transform: rotate(-45deg);
}
.p-global_nav__sub .c-close .c-label {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 2;
}
.p-global_nav #GLOBAL_SUB_NAV_STATUS {
  position: fixed;
  top: 0;
  left: 0;
  appearance: none;
  opacity: 0;
  transform: translate(-100%, -100%);
}
body.is-debug .p-global_nav #GLOBAL_SUB_NAV_STATUS {
  appearance: auto;
  opacity: 1;
  transform: translate(100%, 100%);
  z-index: 10000;
}
@media print, screen and (min-width: 768px) {
  .p-global_nav #GLOBAL_SUB_NAV_STATUS {
    display: none;
  }
}
.p-global_nav #GLOBAL_SUB_NAV_STATUS:checked ~ .p-global_nav__sub {
  transform: translateY(0);
}

.p-hamburger {
  width: 56px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
@media print, screen and (min-width: 768px) {
  .p-hamburger {
    display: none;
  }
}
.p-hamburger .c-patty {
  width: clamp(20.4799999992px, 24 / 390 * 100vw, 24px);
  height: clamp(20.4799999992px, 24 / 390 * 100vw, 24px);
  position: relative;
}
.p-hamburger .c-patty::before, .p-hamburger .c-patty::after {
  content: "";
  height: 1px;
  background: #000;
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  margin: auto;
}
.p-hamburger .c-patty::before {
  transform: translateY(-2px);
  left: -8px;
}
.p-hamburger .c-patty::after {
  transform: translateY(6px);
  left: 0;
}
.p-hamburger .c-label {
  font-weight: bold;
  line-height: 1.5;
  word-break: keep-all;
  font-size: clamp(10.2399999996px, 12 / 390 * 100vw, 12px);
}

.p-recommend_bnr {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-recommend_bnr {
    padding: 32px 0;
    justify-content: center;
  }
}
@media screen and (max-width: 769px) {
  .p-recommend_bnr {
    padding: 32px 14px;
  }
}
.p-recommend_bnr .c-list {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-recommend_bnr .c-list {
    width: 1272px;
    justify-content: center;
    gap: 48px;
  }
}
@media screen and (max-width: 769px) {
  .p-recommend_bnr .c-list {
    flex-direction: column;
    gap: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-recommend_bnr .c-list li {
    width: 480px;
  }
}
.p-recommend_bnr .c-list a {
  aspect-ratio: 960/540;
  display: flex;
  overflow: hidden;
}
@media print, screen and (min-width: 768px) {
  .p-recommend_bnr .c-list a {
    transition: 0.2s;
    border-radius: 8px;
  }
  .p-recommend_bnr .c-list a:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 769px) {
  .p-recommend_bnr .c-list a {
    border-radius: 6px;
  }
}
.p-recommend_bnr .c-list img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.p-shop_lineup {
  padding-top: 20px;
  position: relative;
}
.p-shop_lineup::after {
  content: "";
  background-image: image-set(url("../img/cmn/bg_shop_lineup.png") 1x, url("../img/cmn/bg_shop_lineup@2x.png") 2x);
  background-size: cover;
  position: absolute;
  top: 3px;
  pointer-events: none;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup::after {
    width: 308px;
    height: 222px;
    right: -32px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup::after {
    width: 78.9743589744vw;
    aspect-ratio: 308/222;
    right: -40px;
  }
}
.p-shop_lineup__inner {
  background: var(--bgColor_shop_lineup);
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup__inner {
    padding: 35px 0 65px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup__inner {
    padding: 14px 16px;
  }
}
.p-shop_lineup__inner .c-heading {
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 2;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup__inner .c-heading {
    font-size: 4rem;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup__inner .c-heading {
    font-size: 3.2rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup__bnr {
    width: 1024px;
    margin-top: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup__bnr {
    margin: 10px;
  }
}
.p-shop_lineup__bnr .c-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup__bnr .c-list {
    gap: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup__bnr .c-list {
    gap: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup__bnr li {
    width: 166px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup__bnr li {
    width: calc((100% - 24px) / 2);
  }
}
.p-shop_lineup__bnr a {
  display: flex;
  aspect-ratio: 166/58;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup__bnr a {
    transition: 0.2s;
  }
  .p-shop_lineup__bnr a:hover {
    opacity: 0.7;
  }
}
.p-shop_lineup__bnr img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup__bnr img {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup .p-tab {
    margin-top: 40px;
    width: 1024px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup .p-tab {
    padding-top: 10px;
    width: 100%;
  }
}
.p-shop_lineup .p-tab__header {
  display: flex;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup .p-tab__header {
    gap: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup .p-tab__header {
    gap: 4px;
  }
}
.p-shop_lineup .p-tab__header__item {
  background: var(--bgColor_tab_header);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 6px 6px 0 0;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup .p-tab__header__item {
    height: 52px;
    width: 216px;
    font-size: 1.6rem;
    transition: 0.2s;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup .p-tab__header__item {
    text-align: center;
    font-size: clamp(10.2399999996px, 12 / 390 * 100vw, 12px);
    line-height: 1.4;
    width: 100%;
    word-break: keep-all;
    padding: 8px 0;
  }
}
.p-shop_lineup .p-tab__header__item.is-active, .p-shop_lineup .p-tab__header__item:hover {
  background: white;
}
.p-shop_lineup .p-tab__content {
  margin-top: -1px;
  background: white;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup .p-tab__content {
    padding: 35px 70px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup .p-tab__content {
    padding: 16px;
  }
}
.p-shop_lineup .p-tab__content .c-list {
  display: flex;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup .p-tab__content .c-list {
    gap: 4px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup .p-tab__content .c-list {
    gap: 8px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup .p-tab__content .c-list li {
    width: 144px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_lineup .p-tab__content .c-list li {
    width: calc((100% - 16px) / 3);
  }
}
.p-shop_lineup .p-tab__content .c-list a {
  aspect-ratio: 420/200;
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-shop_lineup .p-tab__content .c-list a {
    transition: 0.2s;
  }
  .p-shop_lineup .p-tab__content .c-list a:hover {
    opacity: 0.7;
  }
}
.p-shop_lineup .p-tab__content .c-list img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.p-tab__content {
  display: none;
}
.p-tab__content.is-show {
  display: block;
}
@media print, screen and (min-width: 768px) {
  .p-tab__header__item {
    cursor: pointer;
  }
  .p-tab__header__item.is-active {
    pointer-events: none;
  }
}

.p-instagram {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media print, screen and (min-width: 768px) {
  .p-instagram {
    margin-top: 80px;
  }
}
@media screen and (max-width: 769px) {
  .p-instagram {
    margin-top: 40px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-instagram .c-heading {
    margin-bottom: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-instagram__wrapper {
    width: 100%;
    max-width: 1512px;
  }
}
@media screen and (max-width: 769px) {
  .p-instagram__wrapper {
    margin-right: 16px;
    margin-left: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-instagram__wrapper #sb_instagram #sbi_images {
    gap: 8px !important;
  }
}
@media screen and (max-width: 769px) {
  .p-instagram__wrapper #sb_instagram #sbi_images {
    gap: 4px !important;
  }
}
.p-instagram__wrapper #sb_instagram #sbi_images .sbi_item {
  aspect-ratio: 1/1;
}

@media print, screen and (min-width: 768px) {
  .p-pick_up {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 769px) {
  .p-pick_up {
    margin-top: 40px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-pick_up .c-heading {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-pick_up .c-heading {
    margin-bottom: 8px;
  }
}
.p-pick_up .c-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 768px) {
  .p-pick_up .c-list {
    width: 100%;
    max-width: 1512px;
    gap: 44px;
  }
}
@media screen and (max-width: 769px) {
  .p-pick_up .c-list {
    padding: 0 26px;
    gap: 18px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-pick_up .c-list li {
    width: 230px;
    height: 230px;
  }
}
@media screen and (max-width: 769px) {
  .p-pick_up .c-list li {
    width: calc((100% - 18px) / 2);
  }
}
.p-pick_up .c-list a {
  aspect-ratio: 1/1;
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-pick_up .c-list a {
    transition: 0.2s;
  }
  .p-pick_up .c-list a:hover {
    opacity: 0.7;
  }
}
.p-pick_up .c-list img {
  object-fit: cover;
  width: 100%;
  height: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.p-our_partners {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media print, screen and (min-width: 768px) {
  .p-our_partners {
    margin-top: 80px;
  }
}
@media screen and (max-width: 769px) {
  .p-our_partners {
    margin-top: 40px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-our_partners .c-heading {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-our_partners .c-heading {
    margin-bottom: 8px;
  }
}
.p-our_partners .c-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 768px) {
  .p-our_partners .c-list {
    width: 100%;
    max-width: 1512px;
    gap: 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-our_partners .c-list {
    margin: 0 24px;
    gap: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-our_partners .c-list li {
    width: 166px;
  }
}
@media screen and (max-width: 769px) {
  .p-our_partners .c-list li {
    width: calc((100% - 16px) / 2);
  }
}
.p-our_partners .c-list a {
  display: flex;
  aspect-ratio: 332/116;
}
@media print, screen and (min-width: 768px) {
  .p-our_partners .c-list a {
    transition: 0.2s;
  }
  .p-our_partners .c-list a:hover {
    opacity: 0.7;
  }
}
.p-our_partners .c-list img {
  object-fit: cover;
  height: auto;
}

@media print, screen and (min-width: 768px) {
  .p-global_footer {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  html[lang=en-US] .p-global_footer {
    gap: 0;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__container {
    width: 100%;
    height: 500px;
    background-image: image-set(url("../img/cmn/bg_footer.png") 1x, url("../img/cmn/bg_footer@2x.png") 2x);
    background-size: cover;
    background-position: center;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__container {
    aspect-ratio: 392/466;
    background-image: image-set(url("../img/cmn/bg_footer_sp.png") 1x, url("../img/cmn/bg_footer_sp@2x.png") 2x);
    background-size: cover;
    background-position: center;
  }
}
@media print, screen and (min-width: 768px) {
  body[data-night_flag=true] .p-global_footer__container {
    background-image: image-set(url("../img/cmn/bg_footer_night.png") 1x, url("../img/cmn/bg_footer_night@2x.png") 2x);
  }
}
@media screen and (max-width: 769px) {
  body[data-night_flag=true] .p-global_footer__container {
    background-image: image-set(url("../img/cmn/bg_footer_night_sp.png") 1x, url("../img/cmn/bg_footer_night_sp@2x.png") 2x);
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__info {
    width: 100%;
    max-width: 1512px;
    padding: 56px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto auto;
    gap: 8px 24px;
    grid-template-areas: "symbol follow_us" "address follow_us" "opening_hours follow_us";
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 0;
  }
}
@media print, screen and (min-width: 768px) {
  html[lang=en-US] .p-global_footer__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__symbol {
    grid-area: symbol;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__symbol .c-figure {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__symbol .c-figure .c-symbol {
    width: 55.8673469388vw;
  }
  .p-global_footer__symbol .c-figure .c-symbol img {
    width: 100%;
    height: auto;
  }
}
.p-global_footer__symbol .c-figure figcaption {
  color: white;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__symbol .c-figure figcaption {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__symbol .c-figure figcaption {
    text-align: center;
    font-size: clamp(15.3599999994px, 18 / 390 * 100vw, 18px);
    line-height: 2;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__address {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__address {
    width: 100%;
  }
}
.p-global_footer__address .c-address {
  color: white;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__address .c-address {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__address .c-address {
    text-align: center;
    font-size: clamp(11.9466666662px, 14 / 390 * 100vw, 14px);
    line-height: 2;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__address .c-btn_wrapper {
    display: flex;
    justify-content: center;
    gap: 18px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__address .c-btn_wrapper li {
    width: 38.2653061224vw;
  }
}
.p-global_footer__address .c-btn {
  height: 26px;
  border-radius: 13px;
  font-weight: bold;
  color: white;
  grid-area: address;
  user-select: none;
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__address .c-btn {
    font-size: 1.4rem;
    padding: 0 12px 0 14px;
    gap: 10px;
    border: 1px solid white;
    transition: 0.2s;
  }
  .p-global_footer__address .c-btn:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__address .c-btn {
    font-size: clamp(11.9466666662px, 14 / 390 * 100vw, 14px);
    padding: 0 8px;
    border: 2px solid white;
    width: 100%;
    white-space: nowrap;
  }
}
.p-global_footer__address .c-btn::before {
  content: "";
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}
@media screen and (max-width: 769px) {
  .p-global_footer__address .c-btn.-address {
    gap: 8px;
  }
}
.p-global_footer__address .c-btn.-address::before {
  width: 12px;
  height: 17px;
  background-image: url(../img/icn/icn_location_white.svg);
}
@media screen and (max-width: 769px) {
  .p-global_footer__address .c-btn.-phone {
    gap: 2px;
  }
}
.p-global_footer__address .c-btn.-phone::before {
  width: 14px;
  height: 15px;
  background-image: url(../img/icn/icn_phone_white.svg);
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__opening_hours {
    color: white;
    line-height: 2;
    grid-area: opening_hours;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__opening_hours .c-headline {
    font-size: 1.6rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__opening_hours small {
    font-size: 1.4rem;
  }
  .p-global_footer__opening_hours small a:hover {
    text-decoration: underline;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__follow_us {
    grid-area: follow_us;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__follow_us {
    margin-top: 10px;
  }
}
.p-global_footer__follow_us__container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.p-global_footer__follow_us .c-heading {
  line-height: 2;
  color: white;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__follow_us .c-heading {
    font-size: 2rem;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__follow_us .c-heading {
    font-size: clamp(17.066666666px, 20 / 390 * 100vw, 20px);
  }
}
.p-global_footer__follow_us .c-list {
  background: white;
  display: inline-flex;
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__follow_us .c-list {
    border-radius: 26px;
    gap: 9px;
    padding: 11px 32px;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__follow_us .c-list {
    border-radius: 40px;
    gap: 16px;
    padding: 14px 32px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__follow_us .c-list a {
    transition: 0.2s;
  }
  .p-global_footer__follow_us .c-list a:hover {
    opacity: 0.7;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__nav {
    display: none;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__nav .c-list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
  }
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__nav a {
    line-height: 2;
    font-size: 1.6rem;
    font-weight: bold;
    word-break: keep-all;
    display: flex;
    position: relative;
  }
  .p-global_footer__nav a::after {
    content: "";
    width: 0;
    height: 1px;
    background: var(--siteColor_main);
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transition: 0.1s;
  }
  .p-global_footer__nav a:hover::after {
    width: 100%;
  }
}
.p-global_footer__copyright {
  display: flex;
  justify-content: center;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-global_footer__copyright {
    padding: 24px 0;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-global_footer__copyright {
    padding: 40px 0 96px;
    font-size: clamp(11.9466666662px, 14 / 390 * 100vw, 14px);
  }
  html[lang=en-US] .p-global_footer__copyright {
    padding-top: 16px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-shop_detail {
    max-width: 1400px;
    min-width: 1152px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 64px;
    padding-top: 64px;
    padding-right: 64px;
    padding-left: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail {
    padding-top: 32px;
  }
}
@media screen and (max-width: 769px) and (max-width: 769px) {
  html[lang=en-US] .p-shop_detail {
    padding-bottom: 24px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__main {
    display: flex;
    gap: 64px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__img {
    width: 100%;
  }
}
.p-shop_detail__img img {
  width: 100%;
  height: auto;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__img img {
    border-radius: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__img img {
    border-radius: 16px;
  }
}
.p-shop_detail__container {
  width: 100%;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__symbol {
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__symbol {
    margin-top: 32px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__symbol img {
    width: auto;
    height: 198px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__symbol img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__info {
    margin-top: 12px;
    padding-right: 16px;
    padding-left: 16px;
  }
}
.p-shop_detail__info .c-detail {
  display: flex;
  flex-direction: column;
}
.p-shop_detail__info .c-detail .c-scope {
  border-bottom: 1px dotted var(--textColor_secondary);
  display: flex;
  align-items: flex-start;
  padding: 0 0 15px;
}
.p-shop_detail__info .c-detail .c-scope + .c-scope {
  padding: 15px 0;
}
.p-shop_detail__info .c-detail .c-scope dt {
  width: 80px;
  line-height: 1.6;
  font-weight: bold;
  flex-shrink: 0;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__info .c-detail .c-scope dt {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__info .c-detail .c-scope dt {
    font-size: clamp(11.9466666662px, 14 / 390 * 100vw, 14px);
  }
}
html[lang=en-US] .p-shop_detail__info .c-detail .c-scope dt {
  width: 100px;
}
.p-shop_detail__info .c-detail .c-scope dd {
  line-height: 1.6;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__info .c-detail .c-scope dd {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__info .c-detail .c-scope dd {
    font-size: clamp(11.9466666662px, 14 / 390 * 100vw, 14px);
  }
}
.p-shop_detail__info .c-detail .c-scope dd a {
  text-decoration: underline;
  word-break: break-all;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__info .c-detail .c-scope dd a:hover {
    text-decoration: none;
  }
}
.p-shop_detail__info .c-social {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__info .c-social a {
    transition: 0.2s;
  }
  .p-shop_detail__info .c-social a:hover {
    opacity: 0.7;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__tax_free {
    margin-top: 4px;
    padding: 16px 0;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__tax_free {
    margin-top: 2px;
    padding: 8px 0;
  }
}
.p-shop_detail__tax_free .c-label {
  display: inline-block;
  color: var(--textColor_red);
  font-weight: bold;
  border: 1px solid var(--textColor_red);
  padding: 4px 8px;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__tax_free .c-label {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__tax_free .c-label {
    font-size: 1.4rem;
  }
}
.p-shop_detail__comment {
  background: var(--bgColor_lgray);
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__comment {
    margin-top: 40px;
    padding: 24px;
    border-radius: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__comment {
    margin-top: 32px;
    margin-right: 16px;
    margin-left: 16px;
    padding: 16px;
    border-radius: 16px;
  }
}
.p-shop_detail__comment p {
  line-height: 1.8;
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__comment p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__comment p {
    font-size: clamp(13.6533333328px, 16 / 390 * 100vw, 16px);
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__shop_news {
    max-width: 1400px;
    width: 100%;
    margin-top: 64px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__shop_news {
    margin-top: 32px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_detail__shop_news .c-heading.-lv1 {
    margin-bottom: 32px;
  }
}
.p-shop_detail .c-btn_wrapper {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 769px) {
  .p-shop_detail .c-btn_wrapper {
    margin-top: 32px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_detail__free_space.p-page {
    width: 100%;
    max-width: 1024px;
    padding: 0;
  }
}

@media print, screen and (min-width: 768px) {
  .p-card.-event {
    width: 288px;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event {
    width: calc((100% - 16px) / 2);
  }
}
.p-card.-event a {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .p-card.-event a {
    gap: 12px;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event a {
    gap: 8px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-card.-event a:hover .c-img img {
    transform: scale(1.1);
  }
}
.p-card.-event .c-img {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  aspect-ratio: 1/1;
  display: flex;
}
.p-card.-event .c-img::after {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 4px 8px;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-card.-event .c-img::after {
    font-size: 1.4rem;
    border-radius: 8px 0 0;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event .c-img::after {
    border-radius: 4px 0 0;
  }
}
.p-card.-event .c-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: 0.2s;
}
.p-card.-event .c-date {
  border-bottom: 1px solid var(--borderColor_date);
}
@media print, screen and (min-width: 768px) {
  .p-card.-event .c-date {
    padding: 4px 0;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event .c-date {
    padding-bottom: 2px;
  }
}
.p-card.-event .c-date time {
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-card.-event .c-date time {
    font-size: 1.4rem;
    line-height: 1.4;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event .c-date time {
    line-height: 1.5;
    font-size: clamp(10.2399999996px, 12 / 390 * 100vw, 12px);
  }
}
@media print, screen and (min-width: 768px) {
  .p-card.-event .c-date time small {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event .c-date time small {
    font-size: 1rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-card.-event .c-headline {
    font-size: 1.4rem;
    line-height: 1.4;
    font-weight: bold;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event .c-headline {
    font-size: clamp(11.0933333329px, 13 / 390 * 100vw, 13px);
    line-height: 1.4;
    font-weight: bold;
  }
}
.p-card.-event .c-sub_text {
  color: var(--textColor_secondary);
}
@media print, screen and (min-width: 768px) {
  .p-card.-event .c-sub_text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-event .c-sub_text {
    font-size: clamp(10.2399999996px, 12 / 390 * 100vw, 12px);
  }
}
.p-card.-event[data-event-type=shop] .c-img::after {
  content: "SHOP NEWS";
  background: var(--bgColor_card_shop);
}
.p-card.-event[data-event-type=event] .c-img::after {
  content: "EVENT";
  background: var(--bgColor_card_event);
}
.p-card.-event[data-event-type=cooking] .c-img::after {
  content: "COOKING";
  background: var(--bgColor_card_cooking);
}
.p-card.-event[data-event-type=workshop] .c-img::after {
  content: "WORKSHOP";
  background: var(--bgColor_card_workshop);
}
.p-card.-event[data-event-type=pop-up] .c-img::after {
  content: "POP-UP";
  background: var(--bgColor_card_pop-up);
}
.p-card.-event[data-event-type=food] .c-img::after {
  content: "食品催事";
  background: var(--bgColor_card_food);
}
.p-card.-shop {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .p-card.-shop {
    padding: 16px 0;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-shop {
    padding: 8px 0;
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: auto auto 1fr;
    gap: 0 16px;
    grid-template-areas: "header header" "image phone" "image category";
  }
}
@media print, screen and (min-width: 768px) {
  .p-card.-shop .c-header {
    margin-bottom: 16px;
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-shop .c-header {
    grid-area: header;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    gap: 8px;
  }
}
.p-card.-shop .c-floor {
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 1.4rem;
}
.p-card.-shop .c-name {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;
}
.p-card.-shop .c-img {
  aspect-ratio: 288/137;
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-card.-shop .c-img {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-shop .c-img {
    grid-area: image;
  }
}
.p-card.-shop .c-img img {
  object-fit: cover;
  width: 100%;
  height: auto;
}
.p-card.-shop .c-phone {
  font-size: 1.4rem;
  line-height: 1.8;
  font-weight: bold;
}
.p-card.-shop .c-category {
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--textColor_secondary);
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
@media screen and (max-width: 769px) {
  .p-card.-shop .c-category {
    grid-area: category;
  }
}
@media print, screen and (min-width: 768px) {
  .p-card.-shop[data-tax-free=true]::after {
    align-self: flex-start;
    content: "TAX FREE";
    line-height: 1;
    font-weight: bold;
    color: var(--textColor_red);
    border: 1px solid var(--textColor_red);
    padding: 3px 8px;
    transform: translateY(-1px);
  }
}
@media print, screen and (min-width: 768px) and (min-width: 768px) {
  .p-card.-shop[data-tax-free=true]::after {
    font-size: 1.6rem;
    margin: 16px 0;
  }
}
@media screen and (max-width: 769px) {
  .p-card.-shop[data-tax-free=true] .c-category::after {
    align-self: flex-start;
    content: "TAX FREE";
    line-height: 1;
    font-weight: bold;
    color: var(--textColor_red);
    border: 1px solid var(--textColor_red);
    padding: 3px 8px;
    transform: translateY(-1px);
    display: inline-flex;
    font-size: 1.4rem;
    margin-top: 8px;
  }
}
.p-card_list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 768px) {
  .p-card_list {
    gap: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-card_list {
    gap: 16px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.p-hansel {
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
@media screen and (max-width: 769px) {
  .p-hansel {
    display: none;
  }
}
.p-hansel__body {
  display: flex;
  justify-content: center;
}
.p-hansel__body li + li {
  display: flex;
  align-items: center;
}
.p-hansel__body li + li::before {
  content: "＞";
  margin: 0 16px;
  color: var(--textColor_secondary);
}
@media print, screen and (min-width: 768px) {
  .p-hansel__body li a, .p-hansel__body li mark {
    font-size: 1.2rem;
    color: var(--textColor_secondary);
    line-height: 1.7;
  }
}
@media print, screen and (min-width: 768px) {
  .p-hansel__body li a:hover {
    text-decoration: underline;
  }
}

@media print, screen and (min-width: 768px) {
  .p-event_detail {
    max-width: 1400px;
    min-width: 1152px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 64px;
    padding-top: 64px;
    padding-right: 64px;
    padding-left: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail {
    padding-top: 32px;
  }
}
.p-event_detail__header {
  display: flex;
  gap: 8px;
  align-items: baseline;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__header {
    justify-content: center;
  }
}
.p-event_detail__header::before {
  background: gray;
  border-radius: 50px;
  white-space: nowrap;
  flex-shrink: 0;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__header::before {
    font-size: 1.6rem;
    padding: 4px 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__header::before {
    font-size: 1.2rem;
    padding: 2px 8px;
    border-radius: 50px;
  }
}
.p-event_detail__header[data-event-type=event]::before {
  content: "EVENT";
  background: var(--bgColor_card_event);
}
.p-event_detail__header[data-event-type=shop]::before {
  content: "SHOP NEWS";
  background: var(--bgColor_card_shop);
}
.p-event_detail__header[data-event-type=cooking]::before {
  content: "COOKING";
  background: var(--bgColor_card_cooking);
}
.p-event_detail__header[data-event-type=workshop]::before {
  content: "WORKSHOP";
  background: var(--bgColor_card_workshop);
}
.p-event_detail__header[data-event-type=pop-up]::before {
  content: "POP-UP";
  background: var(--bgColor_card_pop-up);
}
.p-event_detail__header[data-event-type=food]::before {
  content: "食品催事";
  background: var(--bgColor_card_food);
}
.p-event_detail__header .c-place {
  font-weight: bold;
  line-height: 1.5;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__header .c-place {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__header .c-place {
    font-size: 1.2rem;
  }
}
.p-event_detail__header a.c-place {
  text-decoration: underline;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__header a.c-place:hover {
    text-decoration: none;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main {
    display: flex;
    gap: 64px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__img {
    width: 100%;
  }
}
.p-event_detail__img img {
  width: 100%;
  height: auto;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__img img {
    border-radius: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__img img {
    border-radius: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__container {
    width: 100%;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info {
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__main_info {
    margin-top: 32px;
  }
}
.p-event_detail__main_info .c-detail {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info .c-detail {
    gap: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__main_info .c-detail {
    gap: 4px;
  }
}
.p-event_detail__main_info .c-detail .c-scope {
  display: flex;
  align-items: baseline;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info .c-detail .c-scope {
    gap: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__main_info .c-detail .c-scope {
    gap: 4px;
  }
}
.p-event_detail__main_info .c-detail .c-scope dt {
  background: #000;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  line-height: 1;
  font-weight: bold;
  flex-shrink: 0;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info .c-detail .c-scope dt {
    width: 96px;
    padding: 4px;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__main_info .c-detail .c-scope dt {
    width: 64px;
    padding: 2px;
    font-size: 1.2rem;
  }
}
.p-event_detail__main_info .c-detail .c-scope dd {
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info .c-detail .c-scope dd {
    font-size: 1.6rem;
    line-height: 1.6875;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__main_info .c-detail .c-scope dd {
    font-size: 1.4rem;
    line-height: 1.5;
    transform: translateY(1px);
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info .c-detail .c-scope dd .c-date {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__main_info .c-detail .c-scope dd .c-date {
    font-size: 1.8rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info .c-detail .c-scope dd .c-date small {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__main_info .c-detail .c-scope dd .c-date small {
    font-size: 1.2rem;
  }
}
.p-event_detail__main_info .c-detail .c-scope dd a.c-place {
  text-decoration: underline;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__main_info .c-detail .c-scope dd a.c-place:hover {
    text-decoration: none;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__description {
    margin-top: 24px;
  }
}
.p-event_detail__description p {
  line-height: 1.8;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__description p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__description p {
    font-size: 1.4rem;
  }
}
.p-event_detail__description .c-lead {
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__description .c-lead {
    font-size: 2.2rem;
    line-height: 1.7;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__description .c-lead {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__description .c-lead + p {
    margin-top: 32px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__description .c-lead + p {
    margin-top: 24px;
  }
}
.p-event_detail__description a {
  text-decoration: underline;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__description a:hover {
    text-decoration: none;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__sub_info {
    margin-top: 24px;
  }
}
.p-event_detail__sub_info .c-detail {
  display: flex;
  flex-direction: column;
}
.p-event_detail__sub_info .c-detail .c-scope {
  border-bottom: 1px dotted var(--textColor_secondary);
  display: flex;
  align-items: flex-start;
  padding: 0 0 15px;
}
.p-event_detail__sub_info .c-detail .c-scope + .c-scope {
  padding: 15px 0;
}
.p-event_detail__sub_info .c-detail .c-scope dt {
  width: 80px;
  line-height: 1.6;
  font-weight: bold;
  flex-shrink: 0;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__sub_info .c-detail .c-scope dt {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__sub_info .c-detail .c-scope dt {
    font-size: clamp(11.9466666662px, 14 / 390 * 100vw, 14px);
    line-height: 1.6;
  }
}
.p-event_detail__sub_info .c-detail .c-scope dd {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__sub_info .c-detail .c-scope dd {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__sub_info .c-detail .c-scope dd {
    font-size: clamp(11.9466666662px, 14 / 390 * 100vw, 14px);
    line-height: 1.6;
  }
}
.p-event_detail__sub_info .c-detail .c-scope dd a {
  text-decoration: underline;
  word-break: break-all;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__sub_info .c-detail .c-scope dd a:hover {
    text-decoration: none;
  }
}
.p-event_detail__sub_info .c-social {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__sub_info .c-social a {
    transition: 0.2s;
  }
  .p-event_detail__sub_info .c-social a:hover {
    opacity: 0.7;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__out_of_parking_service {
    margin-top: 4px;
    padding: 16px 0;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__out_of_parking_service {
    margin-top: 2px;
    padding: 8px 0;
  }
}
.p-event_detail__out_of_parking_service .c-label {
  display: inline-block;
  color: var(--textColor_red);
  font-weight: bold;
  border: 1px solid var(--textColor_red);
  padding: 4px 8px;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__out_of_parking_service .c-label {
    font-size: 1.6rem;
  }
}
.p-event_detail .c-btn_wrapper {
  display: flex;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail .c-btn_wrapper {
    margin-top: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail .c-btn_wrapper {
    margin-top: 32px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__free_space {
    margin-top: 64px;
  }
}
.p-event_detail__free_space:empty {
  display: none;
}
.p-event_detail__closed {
  color: var(--textColor_red);
  border: 1px solid;
  padding: 4px;
  line-height: 1.6;
  font-weight: bold;
  text-align: center;
}
@media print, screen and (min-width: 768px) {
  .p-event_detail__closed {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-event_detail__closed {
    font-size: 1.4rem;
  }
}

.p-profile {
  border: 1px solid black;
}
@media print, screen and (min-width: 768px) {
  .p-profile {
    display: grid;
    grid-template-columns: 240px auto 1fr;
    grid-template-rows: auto auto 1fr;
    gap: 4px 16px;
    grid-template-areas: "image title title" "image organization name" "image description description";
    padding: 24px 72px 24px 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 10px;
  }
}
.p-profile .c-img {
  grid-area: image;
}
@media screen and (max-width: 769px) {
  .p-profile .c-img {
    order: 2;
    padding-bottom: 18px;
  }
}
.p-profile .c-img img {
  height: auto;
  width: 208px;
}
.p-profile .c-tle {
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2;
}
@media print, screen and (min-width: 768px) {
  .p-profile .c-tle {
    grid-area: title;
    padding-bottom: 14px;
  }
}
@media screen and (max-width: 769px) {
  .p-profile .c-tle {
    order: 1;
    padding-bottom: 18px;
  }
}
.p-profile .c-organization {
  font-weight: bold;
  line-height: 2;
}
@media print, screen and (min-width: 768px) {
  .p-profile .c-organization {
    grid-area: organization;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-profile .c-organization {
    order: 3;
    font-size: 1.4rem;
  }
}
.p-profile .c-name {
  font-weight: bold;
  line-height: 2;
}
@media print, screen and (min-width: 768px) {
  .p-profile .c-name {
    grid-area: name;
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-profile .c-name {
    order: 4;
    font-size: 1.4rem;
    margin-bottom: 18px;
  }
}
.p-profile .c-desc {
  grid-area: description;
  font-size: 1.3rem;
  line-height: 1.8;
}
@media screen and (max-width: 769px) {
  .p-profile .c-desc {
    order: 5;
  }
}
.p-profile .c-desc a {
  text-decoration: underline;
}
@media print, screen and (min-width: 768px) {
  .p-profile .c-desc a:hover {
    text-decoration: none;
  }
}

[class*=wp-block-lazyblock] + [class*=wp-block-lazyblock] {
  margin-top: 24px;
}

@media print, screen and (min-width: 768px) {
  .p-floor_guide {
    width: 100%;
    max-width: 1400px;
    margin: 64px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide {
    margin-top: 32px;
  }
}
.p-floor_guide__header .c-list {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__header .c-list {
    gap: 24px;
    width: 1104px;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide__header .c-list {
    gap: 16px;
    flex-direction: column;
    align-items: center;
  }
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__header .c-list li {
    width: 100%;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide__header .c-list li {
    width: 270px;
  }
}
.p-floor_guide__header .c-list li a {
  background: var(--bgColor_lgray);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  font-weight: bold;
  border-radius: 48px;
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__header .c-list li a {
    height: 48px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide__header .c-list li a {
    height: 30px;
    font-size: 1.4rem;
  }
}
.p-floor_guide__header .c-list li a.is-current {
  background: var(--textColor_primary);
  color: white;
  pointer-events: none;
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__tab {
    width: 100%;
    margin-top: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide__tab {
    margin-top: 32px;
  }
}
.p-floor_guide__tab .p-tab__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__tab .p-tab__header {
    gap: 24px;
    margin-bottom: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide__tab .p-tab__header {
    gap: 16px;
    margin-bottom: 32px;
    flex-wrap: wrap;
  }
}
.p-floor_guide__tab .p-tab__header__item {
  background: var(--bgColor_lgray);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 56px;
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__tab .p-tab__header__item {
    width: 56px;
    height: 56px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide__tab .p-tab__header__item {
    width: calc((100% - 64px) / 5);
    aspect-ratio: 1/1;
    font-size: 1.6rem;
  }
}
.p-floor_guide__tab .p-tab__header__item.is-active {
  background: var(--textColor_primary);
  color: white;
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__tab .p-tab__header.-floor_map {
    margin-bottom: 40px;
  }
}
.p-floor_guide__tab .p-tab__header.-flex {
  gap: 8px;
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__tab .p-tab__header.-flex {
    width: 1104px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media print, screen and (min-width: 768px) {
  .p-floor_guide__tab .p-tab__header.-flex .p-tab__header__item {
    width: auto;
    font-size: 1.8rem;
    padding: 0 24px;
    height: 44px;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_guide__tab .p-tab__header.-flex .p-tab__header__item {
    width: auto;
    aspect-ratio: auto;
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 4px 16px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-floor_map {
    width: 1104px;
    margin: 0 auto;
  }
}
@media print, screen and (min-width: 768px) {
  .p-floor_map__img {
    padding: 24px 0;
    text-align: center;
  }
  .p-floor_map__img img {
    width: 600px;
    height: auto;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_map__img {
    padding: 16px 0;
  }
  .p-floor_map__img img {
    width: 100%;
    height: auto;
  }
}
@media print, screen and (min-width: 768px) {
  .p-floor_map__shop_list {
    margin-top: 40px;
    margin-right: auto;
    margin-left: auto;
    width: 823px;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_map__shop_list {
    margin-top: 32px;
  }
}
.p-floor_map__shop_list .c-list {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-floor_map__shop_list .c-list {
    gap: 16px 24px;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_map__shop_list .c-list {
    flex-direction: column;
    gap: 16px;
  }
}
.p-floor_map__shop_list .c-list .c-scope {
  display: flex;
  line-height: 1.5;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-floor_map__shop_list .c-list .c-scope {
    width: calc((100% - 24px) / 2);
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_map__shop_list .c-list .c-scope {
    font-size: 1.4rem;
  }
}
.p-floor_map__shop_list .c-list .c-scope dt {
  width: 2em;
  text-align: right;
  margin-right: 0.5em;
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-shrink: 0;
}
.p-floor_map__shop_list .c-list .c-scope dd {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media print, screen and (min-width: 768px) {
  .p-floor_map__shop_list .c-list .c-scope dd {
    gap: 4px 12px;
  }
  .p-floor_map__shop_list .c-list .c-scope dd a:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_map__shop_list .c-list .c-scope dd {
    gap: 6px 4px;
    margin-bottom: -4px;
  }
}
.p-floor_map__shop_list .c-list .c-scope[data-tax-free=true] dd::after {
  content: "TAX FREE";
  line-height: 1;
  font-weight: bold;
  color: var(--textColor_red);
  border: 1px solid var(--textColor_red);
  padding: 3px 8px;
  transform: translateY(-1px);
}
@media print, screen and (min-width: 768px) {
  .p-floor_map__shop_list .c-list .c-scope[data-tax-free=true] dd::after {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-floor_map__shop_list .c-list .c-scope[data-tax-free=true] dd::after {
    font-size: 1.4rem;
  }
}

@media print, screen and (min-width: 768px) {
  .p-home_slider {
    position: relative;
    background-image: url(../img/home/bg_slider.png);
    background-repeat: repeat-x;
    background-position: center top;
    margin-top: -10px;
    margin-bottom: 70px;
    padding-top: 60px;
  }
}
@media screen and (max-width: 769px) {
  .p-home_slider {
    margin-right: -16px;
    margin-left: -16px;
    background-size: auto;
  }
}
@media print, screen and (min-width: 768px) {
  .p-home_slider .slick-list {
    overflow: unset !important;
  }
}
@media screen and (max-width: 769px) {
  .p-home_slider__stage {
    background-image: url(../img/home/bg_slider.png);
    background-repeat: repeat-x;
    background-position: center top;
    background-size: auto 100%;
    padding-top: 48px;
    padding-right: 0;
    padding-bottom: 48px;
    padding-left: 0;
    margin-bottom: 16px;
  }
}
.p-home_slider__stage .slick-track {
  align-items: center;
  display: flex;
  justify-content: center;
}
.p-home_slider__stage .c-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media print, screen and (min-width: 768px) {
  .p-home_slider__stage .c-list {
    height: 500px;
  }
}
.p-home_slider__stage li {
  flex-shrink: 0;
}
@media print, screen and (min-width: 768px) {
  .p-home_slider__stage li {
    width: 416px;
    padding: 0 33px;
    transition: 0.3s;
  }
}
@media screen and (max-width: 769px) {
  .p-home_slider__stage li {
    width: 100%;
    padding: 0 20px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-home_slider__stage li.slick-center {
    transform: scale(1.2, 1.2);
  }
}
.p-home_slider__stage a {
  aspect-ratio: 1/1;
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-home_slider__stage a:hover img {
    filter: brightness(110%);
  }
}
.p-home_slider__stage img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
@media print, screen and (min-width: 768px) {
  .p-home_slider__stage img {
    transition: 0.35s;
  }
}
.p-home_slider__arrows {
  width: 595px;
  height: 0;
  position: absolute;
  top: 310px;
  right: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}
@media screen and (max-width: 769px) {
  .p-home_slider__arrows {
    display: none;
  }
}
.p-home_slider__arrow {
  width: 28px;
  height: 61px;
  overflow: hidden;
  text-indent: -100vw;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.p-home_slider__arrow.-prev {
  background-image: url(../img/icn/icn_arrow_slider_prev.svg);
}
.p-home_slider__arrow.-next {
  background-image: url(../img/icn/icn_arrow_slider_next.svg);
}
.p-home_slider__marker {
  display: flex;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-home_slider__marker {
    margin-top: 70px;
  }
}
.p-home_slider__marker .c-list {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.p-home_slider__marker .c-list li {
  width: 14px;
  height: 14px;
  background: var(--bgColor_slider_marker);
  border-radius: 14px;
}
@media print, screen and (min-width: 768px) {
  .p-home_slider__marker .c-list li {
    cursor: pointer;
  }
}
.p-home_slider__marker .c-list li.slick-active {
  background: var(--siteColor_main);
  pointer-events: none;
}
.p-home_slider__marker .c-list li button {
  appearance: none;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
@media print, screen and (min-width: 768px) {
  .p-home_event_campaign {
    width: 100%;
    max-width: 1400px;
    padding: 0 64px 20px;
    margin: 70px auto;
  }
}
@media print, screen and (min-width: 768px) {
  .p-home_event_campaign .c-heading.-lv1 {
    margin-bottom: 8px;
  }
}
.p-home_event_campaign__date {
  display: flex;
  justify-content: center;
  font-weight: bold;
  line-height: 2;
}
@media print, screen and (min-width: 768px) {
  .p-home_event_campaign__date {
    font-size: 2rem;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-home_event_campaign__date {
    font-size: 1.7rem;
    margin-bottom: 22px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-home_event_campaign .p-card_list {
    gap: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-home_event_campaign .p-card_list {
    gap: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-home_event_campaign .p-card_list .p-card {
    width: calc((100% - 120px) / 4);
  }
}
@media screen and (max-width: 769px) {
  .p-home_event_campaign .p-card_list .p-card {
    width: calc((100% - 16px) / 2);
  }
}
.p-home_event_campaign .c-btn_wrapper {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}
.p-home_information {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .p-home_information {
    width: 1000px;
    margin: 102px auto;
    gap: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-home_information {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-home_information .c-heading.-lv1 {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-home_information .c-heading.-lv1 {
    margin-bottom: 4px;
  }
}
.p-home_information .c-btn_wrapper {
  display: flex;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-home_information .c-btn_wrapper {
    margin-top: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-home_information .c-btn_wrapper {
    margin-top: 16px;
  }
}
.p-home_kv {
  display: flex;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .p-home_kv {
    width: 950px;
    margin-top: 8px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    gap: 36px;
  }
}
@media screen and (max-width: 769px) {
  .p-home_kv {
    margin-right: -16px;
    margin-top: 8px;
    margin-left: -16px;
    margin-bottom: 16px;
    gap: 20px;
  }
}
.p-home_kv .c-img img {
  width: 100%;
  height: auto;
}
@media print, screen and (min-width: 768px) {
  .p-home_kv figcaption {
    font-size: 2rem;
    line-height: 2;
    text-align: center;
  }
}
@media screen and (max-width: 769px) {
  .p-home_kv figcaption {
    font-size: 1.8rem;
    line-height: 1.6;
    text-align: center;
  }
}

@media screen and (max-width: 769px) {
  .p-information {
    padding-bottom: 16px;
  }
}
.p-information .c-list {
  display: flex;
  flex-direction: column;
}
.p-information .c-list .c-scope {
  display: flex;
  border-bottom: 1px solid black;
  line-height: 1.6;
  padding: 16px 0;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-information .c-list .c-scope {
    font-size: 1.8rem;
    align-items: baseline;
  }
}
@media screen and (max-width: 769px) {
  .p-information .c-list .c-scope {
    flex-direction: column;
    gap: 8px;
    font-size: 1.4rem;
  }
}
.p-information .c-list .c-scope + .c-scope {
  margin-top: 10px;
}
@media print, screen and (min-width: 768px) {
  .p-information .c-list .c-scope dt {
    width: 112px;
    margin-right: 16px;
    flex-shrink: 0;
  }
}
@media screen and (max-width: 769px) {
  .p-information .c-list .c-scope dt {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-information .c-list .c-scope dt time {
    width: 80px;
    flex-shrink: 0;
  }
}
@media print, screen and (min-width: 768px) {
  .p-information .c-list .c-scope dd {
    gap: 18px;
    display: flex;
    align-items: baseline;
  }
}
@media print, screen and (min-width: 768px) {
  .p-information .c-list .c-scope dd a:hover {
    text-decoration: underline;
  }
}
@media screen and (max-width: 769px) {
  .p-information .c-list .c-scope[data-information-type=press_release] dt::after {
    content: "プレスリリース";
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--textColor_secondary);
    white-space: nowrap;
    border-radius: 50px;
    color: white;
    font-size: 1.2rem;
    height: 24px;
    padding: 0 8px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-information .c-list .c-scope[data-information-type=press_release] dd::before {
    content: "プレスリリース";
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--textColor_secondary);
    white-space: nowrap;
    border-radius: 50px;
    color: white;
    font-size: 1.2rem;
    height: 24px;
    padding: 0 8px;
  }
}

.p-notification li {
  margin: 8px 0;
}
@media print, screen and (min-width: 768px) {
  .p-notification li:last-child {
    margin-bottom: 16px;
  }
}
.p-notification a {
  display: block;
  border: 1px solid var(--textColor_red);
  color: var(--textColor_red);
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-notification a {
    padding: 4px;
    font-size: 1.6rem;
    text-align: center;
    line-height: 1.6;
    width: 1024px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (max-width: 769px) {
  .p-notification a {
    padding: 5px;
    font-size: 1.4rem;
  }
}

.p-shop_list .c-list {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-shop_list .c-list {
    gap: 40px;
    width: 100%;
    padding: 0 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-shop_list .c-list {
    flex-direction: column;
    gap: 8px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-shop_list .c-list li {
    width: calc((100% - 120px) / 4);
  }
}

@media print, screen and (min-width: 768px) {
  .p-event_list {
    max-width: 1400px;
    min-width: 1152px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 64px;
    padding-top: 64px;
    padding-right: 64px;
    padding-left: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_list {
    padding-bottom: 32px;
  }
}
.p-event_list .p-tab__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
@media print, screen and (min-width: 768px) {
  .p-event_list .p-tab__header {
    width: 1024px;
    margin-right: auto;
    margin-left: auto;
    gap: 16px;
    margin-bottom: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_list .p-tab__header {
    gap: 8px;
    margin-bottom: 40px;
  }
}
.p-event_list .p-tab__header__item {
  background: var(--bgColor_lgray);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .p-event_list .p-tab__header__item {
    width: 268px;
    font-size: 1.8rem;
    height: 48px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_list .p-tab__header__item {
    font-size: 1.6rem;
    height: 32px;
    padding: 0 16px;
    min-width: 96px;
  }
}
.p-event_list .p-tab__header__item.is-active {
  background: black;
  color: white;
}
.p-event_list .p-tab__header__item input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
@media print, screen and (min-width: 768px) {
  .p-event_list .p-tab__content .p-card_list {
    gap: 32px 48px;
  }
}
@media screen and (max-width: 769px) {
  .p-event_list .p-tab__content .p-card_list {
    gap: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-event_list .p-tab__content .p-card.-event {
    width: calc((100% - 144px) / 4);
  }
}

@media print, screen and (min-width: 768px) {
  .p-information_detail {
    width: 1200px;
    margin-top: 64px;
    margin-right: auto;
    margin-bottom: 64px;
    margin-left: auto;
    padding-right: 64px;
    padding-left: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-information_detail {
    padding-top: 32px;
    padding-bottom: 18px;
  }
}
.p-information_detail__header {
  display: flex;
  gap: 8px;
  align-items: baseline;
  justify-content: center;
}
.p-information_detail__header::before {
  background: gray;
  border-radius: 50px;
  white-space: nowrap;
  flex-shrink: 0;
}
@media print, screen and (min-width: 768px) {
  .p-information_detail__header::before {
    font-size: 1.6rem;
    padding: 4px 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-information_detail__header::before {
    font-size: 1.2rem;
    padding: 2px 8px;
    border-radius: 50px;
  }
}
.p-information_detail__header[data-press_release=true]::before {
  content: "プレスリリース";
  background: var(--textColor_secondary);
  color: white;
}
.p-information_detail__header .c-date {
  font-weight: bold;
  line-height: 1.5;
}
@media print, screen and (min-width: 768px) {
  .p-information_detail__header .c-date {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 769px) {
  .p-information_detail__header .c-date {
    font-size: 1.2rem;
  }
}
.p-information_detail .c-btn_wrapper {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-information_detail .c-btn_wrapper {
    margin-top: 64px;
    gap: 20px;
    justify-content: center;
  }
}
@media screen and (max-width: 769px) {
  .p-information_detail .c-btn_wrapper {
    margin-top: 32px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-information_detail__free_space {
    margin-top: 64px;
  }
}
.p-information_detail__free_space:empty {
  display: none;
}

.p-gutenberg p {
  line-height: 1.6;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-gutenberg p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-gutenberg p {
    font-size: 1.6rem;
  }
}
.p-gutenberg p + p {
  margin-top: 1.6em;
}
.p-gutenberg p a {
  text-decoration: underline;
}
@media screen and (max-width: 769px) {
  .p-gutenberg p a {
    color: var(--textColor_link);
  }
}
@media print, screen and (min-width: 768px) {
  .p-gutenberg p a:hover {
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .p-press_release {
    width: 1024px;
    margin-top: 64px;
    margin-right: auto;
    margin-bottom: 64px;
    margin-left: auto;
  }
}
@media screen and (max-width: 769px) {
  .p-press_release {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-press_release__header {
    margin-bottom: 64px;
  }
}
.p-press_release__header .c-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-press_release__header .c-list {
    gap: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-press_release__header .c-list {
    gap: 4px;
  }
}
.p-press_release__header .c-list a {
  display: inline-flex;
  background: var(--bgColor_lgray);
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 48px;
}
@media print, screen and (min-width: 768px) {
  .p-press_release__header .c-list a {
    font-size: 2.2rem;
    height: 48px;
    padding: 0 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-press_release__header .c-list a {
    font-size: 1.6rem;
    height: 32px;
    padding: 0 16px;
  }
}
.p-press_release__header .c-list a.is-current {
  background: #000;
  color: white;
}
.p-press_release .c-btn_wrapper {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-press_release .c-btn_wrapper {
    margin-top: 64px;
    gap: 20px;
    justify-content: center;
  }
}
@media screen and (max-width: 769px) {
  .p-press_release .c-btn_wrapper {
    margin-top: 32px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}

@media print, screen and (min-width: 768px) {
  .p-recruit {
    margin: 64px auto;
    width: 1024px;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit {
    margin: 32px 0 0;
  }
}
@media print, screen and (min-width: 768px) {
  .p-recruit__header {
    margin-bottom: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__header {
    margin-bottom: 32px;
  }
}
.p-recruit__header .c-lead {
  font-weight: bold;
  line-height: 1.6;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__header .c-lead {
    font-size: 1.8rem;
    text-align: center;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__header .c-lead {
    font-size: 1.4rem;
  }
}
.p-recruit__item {
  background: var(--bgColor_lgray);
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item {
    border-radius: 24px;
    padding: 48px;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__item {
    border-radius: 16px;
    padding: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item + .p-recruit__item {
    margin-top: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__item + .p-recruit__item {
    margin-top: 32px;
  }
}
.p-recruit__item__header {
  background: white;
  display: flex;
  border-radius: 100px;
  font-weight: bold;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__header {
    margin-bottom: 24px;
    padding: 16px;
    gap: 36px;
    align-items: center;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__item__header {
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 24px;
    gap: 4px;
    margin-bottom: 24px;
  }
}
.p-recruit__item__header dt {
  background: #000;
  color: white;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__header dt {
    height: 36px;
    padding: 0 16px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__item__header dt {
    font-size: 1.2rem;
    padding: 0 8px;
    height: 24px;
  }
}
.p-recruit__item__header dd {
  line-height: 1.6;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__header dd {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__item__header dd {
    font-size: 1.8rem;
  }
}
.p-recruit__item__header dd a {
  text-decoration: underline;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__header dd a:hover {
    text-decoration: none;
  }
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__detail {
    padding: 0 32px;
  }
}
.p-recruit__item__detail .c-copy {
  font-weight: bold;
  line-height: 1.6;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__detail .c-copy {
    margin-bottom: 32px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__item__detail .c-copy {
    font-size: 1.4rem;
    margin-bottom: 24px;
  }
}
.p-recruit__item__detail .c-list {
  display: flex;
  flex-direction: column;
}
.p-recruit__item__detail .c-scope {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px dashed var(--textColor_secondary);
  line-height: 1.6;
  padding-bottom: 15px;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__detail .c-scope {
    font-size: 1.6rem;
    gap: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-recruit__item__detail .c-scope {
    font-size: 1.4rem;
    gap: 4px;
  }
}
.p-recruit__item__detail .c-scope + .c-scope {
  padding-top: 15px;
}
.p-recruit__item__detail .c-scope dt {
  font-weight: bold;
  flex-shrink: 0;
  width: 80px;
}
.p-recruit__item__detail .c-scope dd {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.p-recruit__item__detail .c-scope dd a {
  text-decoration: underline;
}
@media print, screen and (min-width: 768px) {
  .p-recruit__item__detail .c-scope dd a:hover {
    text-decoration: none;
  }
}

@media print, screen and (min-width: 768px) {
  .p-page > * + * {
    margin-top: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-page > * + * {
    margin-top: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page > * + h2,
  .p-page > * + .p-card_list,
  .p-page > * + .p-staff_info {
    margin-top: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-page > * + h2,
  .p-page > * + .p-card_list,
  .p-page > * + .p-staff_info {
    margin-top: 32px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page > * + h3,
  .p-page > * + .wp-block-columns,
  .p-page > * + .wp-block-image {
    margin-top: 48px;
  }
}
@media screen and (max-width: 769px) {
  .p-page > * + h3,
  .p-page > * + .wp-block-columns,
  .p-page > * + .wp-block-image {
    margin-top: 28px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page > * + h4 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-page > * + h4 {
    margin-top: 24px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page {
    width: 1200px;
    margin-top: 64px;
    margin-right: auto;
    margin-bottom: 64px;
    margin-left: auto;
    padding-right: 64px;
    padding-left: 64px;
  }
}
@media screen and (max-width: 769px) {
  .p-page {
    padding-top: 32px;
    padding-bottom: 18px;
  }
}
.p-page h2 {
  background: var(--gradation_g);
  line-height: 1.6;
  font-weight: bold;
  padding: 6px 16px;
}
@media print, screen and (min-width: 768px) {
  .p-page h2 {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 769px) {
  .p-page h2 {
    font-size: 1.8rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page h2 + h3 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-page h2 + h3 {
    margin-top: 16px;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page h2 + p {
    margin-top: 32px;
  }
}
@media screen and (max-width: 769px) {
  .p-page h2 + p {
    margin-top: 16px;
  }
}
.p-page h3 {
  font-weight: bold;
  position: relative;
  line-height: 1.6;
}
@media print, screen and (min-width: 768px) {
  .p-page h3 {
    font-size: 2.2rem;
    padding-left: 33px;
  }
}
@media screen and (max-width: 769px) {
  .p-page h3 {
    font-size: 1.8rem;
    padding-left: 28px;
  }
}
.p-page h3::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 6px;
}
@media print, screen and (min-width: 768px) {
  .p-page h3::before {
    width: 23px;
    height: 23px;
    border: 7px solid var(--siteColor_main);
  }
}
@media screen and (max-width: 769px) {
  .p-page h3::before {
    width: 18px;
    height: 18px;
    border: 5px solid var(--siteColor_main);
  }
}
@media print, screen and (min-width: 768px) {
  .p-page h3 + h4,
  .p-page h3 + p {
    margin-top: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-page h3 + h4,
  .p-page h3 + p {
    margin-top: 16px;
  }
}
.p-page h4 {
  color: var(--siteColor_main);
  font-weight: bold;
  line-height: 1.6;
}
@media print, screen and (min-width: 768px) {
  .p-page h4 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 769px) {
  .p-page h4 {
    font-size: 1.6rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page h4 + p {
    margin-top: 16px;
  }
}
@media screen and (max-width: 769px) {
  .p-page h4 + p {
    margin-top: 8px;
  }
}
.p-page p {
  font-weight: bold;
  line-height: 1.8;
}
@media print, screen and (min-width: 768px) {
  .p-page p {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-page p {
    font-size: 1.4rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page p + h3 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 769px) {
  .p-page p + h3 {
    margin-top: 20px;
  }
}
.p-page ul.wp-block-list {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  line-height: 1.6;
  gap: 8px;
}
@media print, screen and (min-width: 768px) {
  .p-page ul.wp-block-list {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-page ul.wp-block-list {
    font-size: 1.4rem;
  }
}
.p-page ul.wp-block-list li {
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .p-page ul.wp-block-list li {
    padding-left: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-page ul.wp-block-list li {
    padding-left: 16px;
  }
}
.p-page ul.wp-block-list li::before {
  content: "";
  border-radius: 14px;
  background: var(--siteColor_main);
  position: absolute;
  left: 0;
}
@media print, screen and (min-width: 768px) {
  .p-page ul.wp-block-list li::before {
    width: 14px;
    height: 14px;
    top: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-page ul.wp-block-list li::before {
    width: 10px;
    height: 10px;
    top: 6px;
  }
}
.p-page ul.c-list.-annotation {
  font-weight: normal;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 1.6;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
@media print, screen and (min-width: 768px) {
  .p-page ul.c-list.-annotation {
    font-size: 1.6rem;
    gap: 4px;
    margin-top: 8px;
  }
}
@media screen and (max-width: 769px) {
  .p-page ul.c-list.-annotation {
    font-size: 1.2rem;
    gap: 2px;
    margin-top: 4px;
  }
}
.p-page ul.c-list.-annotation li {
  padding-left: 1.2em;
}
.p-page ul.c-list.-annotation li::before {
  content: "※";
  position: absolute;
  left: 0;
}
@media screen and (max-width: 769px) {
  .p-page .wp-block-table {
    overflow: auto;
  }
}
.p-page .wp-block-table table {
  min-width: 100%;
  border-collapse: collapse;
}
.p-page .wp-block-table table th, .p-page .wp-block-table table td {
  border-top: 1px solid var(--borderColor_table);
  border-bottom: 1px solid var(--borderColor_table);
  font-weight: bold;
  vertical-align: middle;
  line-height: 2;
}
@media print, screen and (min-width: 768px) {
  .p-page .wp-block-table table th, .p-page .wp-block-table table td {
    padding: 16px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-page .wp-block-table table th, .p-page .wp-block-table table td {
    font-size: 1.4rem;
    padding: 4px;
  }
}
.p-page .wp-block-table table th + th, .p-page .wp-block-table table th + td, .p-page .wp-block-table table td + th, .p-page .wp-block-table table td + td {
  border-left: 1px solid var(--borderColor_table);
}
.p-page .wp-block-table table th {
  background: var(--bgColor_lgray);
  white-space: nowrap;
}
.p-page .wp-block-table table thead th, .p-page .wp-block-table table thead td,
.p-page .wp-block-table table tfoot th,
.p-page .wp-block-table table tfoot td {
  background: var(--bgColor_tab_header);
}
.p-page .wp-block-table table thead th + th, .p-page .wp-block-table table thead th + td, .p-page .wp-block-table table thead td + th, .p-page .wp-block-table table thead td + td,
.p-page .wp-block-table table tfoot th + th,
.p-page .wp-block-table table tfoot th + td,
.p-page .wp-block-table table tfoot td + th,
.p-page .wp-block-table table tfoot td + td {
  border-left: 1px solid white;
}
.p-page .wp-block-buttons .wp-block-button a {
  background: var(--bgColor_btn_primary);
  font-weight: bold;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  line-height: 2;
}
@media print, screen and (min-width: 768px) {
  .p-page .wp-block-buttons .wp-block-button a {
    font-size: 2rem;
    padding: 8px 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-page .wp-block-buttons .wp-block-button a {
    font-size: 1.6rem;
    padding: 4px 16px;
  }
}
.p-page .wp-block-buttons .wp-block-button a::after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url(../img/icn/icn_arrow_r.svg);
  background-repeat: no-repeat;
}
@media print, screen and (min-width: 768px) {
  .p-page .wp-block-buttons .wp-block-button a:hover {
    transition: 0.2s;
  }
  .p-page .wp-block-buttons .wp-block-button a:hover:hover {
    opacity: 0.7;
  }
}
.p-page .wp-block-columns {
  display: flex;
}
@media print, screen and (min-width: 768px) {
  .p-page .wp-block-columns {
    gap: 40px;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 769px) {
  .p-page .wp-block-columns {
    gap: 24px;
    flex-direction: column;
  }
}
@media print, screen and (min-width: 768px) {
  .p-page .wp-block-columns + .wp-block-columns {
    margin-top: 40px;
  }
}
.p-page .wp-block-columns .wp-block-column {
  margin-top: 0;
  width: 100%;
}
.p-page .wp-block-image.size-full img {
  width: 100%;
  height: auto;
}
.p-page .wp-block-image .alignleft {
  text-align: left;
}
.p-page .wp-block-image .alignleft img {
  max-width: 100%;
  height: auto;
}
.p-page .wp-block-image .aligncenter {
  text-align: center;
}
.p-page .wp-block-image .aligncenter img {
  max-width: 100%;
  height: auto;
}
.p-page .wp-block-image figcaption {
  font-weight: bold;
  margin-top: 10px;
  line-height: 1.6;
}
@media print, screen and (min-width: 768px) {
  .p-page .wp-block-image figcaption {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 769px) {
  .p-page .wp-block-image figcaption {
    font-size: 1.4rem;
  }
}

.wpcf7 h3 + p {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  font-weight: normal;
}
@media print, screen and (min-width: 768px) {
  .wpcf7 h3 + p {
    margin-top: 16px;
  }
}
@media screen and (max-width: 769px) {
  .wpcf7 h3 + p {
    margin-top: 8px;
  }
}
.wpcf7 h3 .c-required {
  margin-left: 10px;
  color: var(--textColor_red);
}
@media print, screen and (min-width: 768px) {
  .wpcf7 h3 .c-required {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 769px) {
  .wpcf7 h3 .c-required {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 769px) {
  .wpcf7 p + h3 {
    margin-top: 32px;
  }
}
.wpcf7 input[type=text],
.wpcf7 input[type=email] {
  line-height: 1.6;
  appearance: none;
  background: var(--bgColor_lgray);
  border: none;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
@media print, screen and (min-width: 768px) {
  .wpcf7 input[type=text],
  .wpcf7 input[type=email] {
    width: 300px;
    height: 77px;
    font-size: 1.8rem;
    padding: 24px;
    border-radius: 8px;
  }
}
@media screen and (max-width: 769px) {
  .wpcf7 input[type=text],
  .wpcf7 input[type=email] {
    width: 100%;
    max-width: 300px;
    height: 58px;
    font-size: 1.6rem;
    padding: 0 16px;
    border-radius: 4px;
  }
}
.wpcf7 input[type=text]::placeholder,
.wpcf7 input[type=email]::placeholder {
  color: var(--txtColor_placeholder);
}
.wpcf7 .wpcf7-list-item {
  margin-left: 0;
  margin-right: 32px;
  display: inline-flex;
  align-items: center;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.wpcf7 .wpcf7-list-item .wpcf7-list-item-label {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 769px) {
  .wpcf7 .wpcf7-list-item .wpcf7-list-item-label {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}
.wpcf7 .wpcf7-list-item .wpcf7-list-item-label::before {
  width: 30px;
  height: 30px;
  background: #000;
  margin-right: 16px;
  border-radius: 50%;
  border: 15px solid var(--bgColor_lgray);
}
.wpcf7 .wpcf7-list-item input {
  position: fixed;
  top: 0;
  left: 0;
  appearance: none;
  opacity: 0;
  transform: translate(-100%, -100%);
}
body.is-debug .wpcf7 .wpcf7-list-item input {
  appearance: auto;
  opacity: 1;
  transform: translate(100%, 100%);
  z-index: 10000;
}
.wpcf7 .wpcf7-list-item input:checked ~ .wpcf7-list-item-label::before {
  border: 9px solid var(--bgColor_lgray);
}
.wpcf7 select {
  line-height: 1.6;
  appearance: none;
  background: var(--bgColor_lgray);
  border: none;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
@media print, screen and (min-width: 768px) {
  .wpcf7 select {
    width: 300px;
    height: 77px;
    font-size: 1.8rem;
    padding: 24px;
    border-radius: 8px;
  }
}
@media screen and (max-width: 769px) {
  .wpcf7 select {
    width: 100%;
    max-width: 300px;
    height: 58px;
    font-size: 1.8rem;
    padding: 0 16px;
    border-radius: 8px;
  }
}
.wpcf7 textarea {
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.6;
  padding: 24px;
  appearance: none;
  background: var(--bgColor_lgray);
  border-radius: 8px;
  border: none;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.wpcf7 textarea[rows="10"] {
  height: 300px;
}
.wpcf7 .c-btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .wpcf7 .c-btn_wrapper {
    margin-top: 24px;
  }
}
@media screen and (max-width: 769px) {
  .wpcf7 .c-btn_wrapper {
    margin-top: 16px;
  }
}
.wpcf7 input.c-btn.-submit {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 2;
  padding: 8px 48px 8px 24px;
  border-radius: 100px;
  gap: 8px;
  font-weight: bold;
  background-color: var(--bgColor_btn_primary);
  background-image: url(../img/icn/icn_arrow_r.svg);
  background-repeat: no-repeat;
  background-position: right 24px center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
.wpcf7 .wpcf7-form-control-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.wpcf7 .wpcf7-not-valid-tip {
  display: inline-flex;
  background: var(--textColor_red);
  color: white;
  font-size: 1.8rem;
  line-height: 1.6;
  padding: 0 8px;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.wpcf7 .wpcf7-response-output {
  text-align: center;
  border: none !important;
  font-size: 1.8rem;
  line-height: 1.6;
  color: var(--textColor_red);
  margin: 10px 0 0 !important;
  padding: 0 !important;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.wpcf7 .wpcf7-spinner {
  position: absolute;
  margin: 0;
}

.p-search_result .c-list {
  display: flex;
  flex-direction: column;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
.p-search_result .c-list li {
  line-height: 1.8;
}
@media print, screen and (min-width: 768px) {
  .p-search_result .c-list li {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 769px) {
  .p-search_result .c-list li {
    font-size: 1.4rem;
  }
}
@media print, screen and (min-width: 768px) {
  .p-search_result .c-list li + li {
    margin-top: 24px;
  }
}
@media screen and (max-width: 769px) {
  .p-search_result .c-list li + li {
    margin-top: 16px;
  }
}
.p-search_result .c-list li a {
  text-decoration: underline;
}
@media print, screen and (min-width: 768px) {
  .p-search_result .c-list li a:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 769px) {
  ._pc {
    display: none !important;
  }
}

@media print, screen and (min-width: 768px) {
  ._sp {
    display: none !important;
  }
}

html[lang=en-US] ._jp {
  display: none;
}

html[lang=ja] ._en {
  display: none;
}