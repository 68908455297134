@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-global_search {
  position: relative;

  @include mi.mq(){
    height: 28px;
  }

  @include mi.mq(sp){
    height: 44px;
    margin: 0 36px;
  }

  .c-input_text {
    input {
      @include mi.mq(){
        width: 182px;
        height: 28px;
        border-radius: 14px;
        background: var(--bgColor_lgray);
        padding: 0 16px;

        &::placeholder {
          color: var(--textColor_secondary);
        }
      }

      @include mi.mq(sp){
        width: 100%;
        height: 44px;
        border-radius: 22px;
        background: white;
        padding: 0 16px;
      }
    }
  }

  .c-btn {
    background: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 19px;
    height: 19px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include mi.mq(){
      right: 9px;
      width: 19px;
      height: 19px;
    }

    @include mi.mq(sp){
      right: 13px;
      width: 26px;
      height: 26px;
    }

    img {
      @include mi.mq(){
        width: 19px;
        height: 19px;
      }

      @include mi.mq(sp){
        width: 26px;
        height: 26px;
      }
    }
  }
}