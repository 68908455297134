@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-global_nav {
  @include mi.mq(){
    grid-area: global_nav;
    display: flex;
    justify-content: flex-end;
  }

  @include mi.mq(sp){
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--bgColor_sp_nav);
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.11);
  }

  &__body {
    @include mi.mq(sp){
      display: flex;
    }

    .c-list {
      display: flex;
      flex: 1;

      @include mi.mq(){
        gap: 16px;
        padding: 3px 0;
      }

      @include mi.mq(sp){
        padding: 9px 0;
      }

      li {
        @include mi.mq(sp){
          width: 100%;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mi.mq(){
          height: 48px;
          padding: 0 8px;
          position: relative;

          &::after {
            content: "";
            height: 1px;
            width: 0;
            background: var(--siteColor_main);
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            position: absolute;
            transition: .1s;
          }

          &.is-current,
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }

        @include mi.mq(sp){
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 4px;
        }

        .c-icn {
          width: mi.sp_size(24);

          @include mi.mq(){
            display: none;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .c-label {
          font-weight: bold;
          line-height: 1.5;
          word-break: keep-all;

          @include mi.mq(){
            font-size: 1.6rem;
          }

          @include mi.mq(sp){
            @include mi.mb_font_size(12);
            text-align: center;

            html[lang="en-US"] & {
              min-height: 34px;
              display: flex;
              align-items: center;
            }
          }

          &.-thin {
            @include mi.mq(sp){
              html[lang="ja"] & {
                letter-spacing: -0.1em;
              }
            }
          }
        }
      }
    }
  }


  &__sub {
    position: fixed;
    right: 0;
    left: 0;
    background: var(--bgColor_sp_nav);
    z-index: 2;
    padding: 24px 26px 70px;
    transition: .2s;
    transform: translateY(101%);

    html[lang="en-US"] & {
      bottom: 0;
      max-height: 100dvh;
      box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    }

    html[lang="ja"] & {
      top: 0;
      height: 100dvh;
    }

    @include mi.mq(){
      display: none;
    }

    .c-list {
      &.-square {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 28px;
        justify-content: center;

        li {
          width: calc((100% - 20px) / 3);
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          width: 100%;
          aspect-ratio: 1 / 1;
          background: white;
          border-radius: 6px;
        }

        .c-icn {
          width: 26px;
          height: 26px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }

        .c-label {
          text-align: center;
          @include mi.mb_font_size(13);
          line-height: 1.5;
          height: 40px;
          display: inline-flex;
          align-items: center;
        }
      }

      &.-thin {
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 24px;

        li {
          width: calc((100% - 10px) / 2);
        }

        a {
          display: flex;
          height: 35px;
          background: white;
          border-radius: 20px;
          @include mi.mb_font_size(13);
        }

      }
    }

    .c-close {
      position: absolute;
      right: 12px;
      bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 4px;

      .c-times {
        width: 25px;
        height: 25px;
        position: relative;

        &::before,
        &::after {
          content: "";
          width: 35px;
          height: 1px;
          background: #000;
          position: absolute;
          top: 0;
          right: -5px;
          bottom: 0;
          left: -5px;
          margin: auto;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }

      .c-label {
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 2;
      }
    }
  }

  #GLOBAL_SUB_NAV_STATUS {
    @include mi.input_invisible;

    @include mi.mq(){
      display: none;
    }

    &:checked {
      ~ {
        .p-global_nav__sub {
          transform: translateY(0);
        }
      }
    }
  }
}