@use "variables" as var;
@use "sass:math";
@use "sass:map";

// *** Media Queries ***
@mixin mq($breakpoint: pc) {
  @media #{map.get(var.$breakpoints, $breakpoint)} {
    @content;
  }
}

// *** 幅390px換算で px を変換
@function sp_size($value) {
  //@return $value / 390 * 100vw;
  $min_size : $value * 0.8533333333px;
  $max_size : $value * 1px;
  @return clamp(#{$min_size}, calc(#{$value} / 390 * 100vw), #{$max_size});
}

// *** モバイルフォントサイズ ***
// 幅320px換算サイズを最小とし、最大を390px換算サイズとする
@mixin mb_font_size($value) {
  $min_size : $value * 0.8533333333px;
  $max_size : $value * 1px;
  font-size: clamp(#{$min_size}, calc(#{$value} / 390 * 100vw), #{$max_size});
}

// *** 不可視化 ***
@mixin input_invisible {
  position: fixed;
  top: 0;
  left: 0;
  appearance: none;
  opacity: 0;
  transform: translate(-100%, -100%);

  body.is-debug & {
    appearance: auto;
    opacity: 1;
    transform: translate(100%, 100%);
    z-index: 10000;
  }
}

@mixin scrollbar_none {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar{
    display:none;
  }
}

@mixin font-secondary {
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}