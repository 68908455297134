@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-event_list {
  @include mi.mq(){
    max-width: 1400px;
    min-width: 1152px;
    width: 100%;
    margin: {
      right: auto;
      left: auto;
      bottom: 64px;
    }
    padding: {
      top: 64px;
      right: 64px;
      left: 64px;
    }
  }

  @include mi.mq(sp){
    padding-bottom: 32px;
  }

  .p-tab {
    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @include mi.mq(){
        width: 1024px;
        margin: {
          right: auto;
          left: auto;
        }
        gap: 16px;
        margin-bottom: 64px;
      }

      @include mi.mq(sp){
        gap: 8px;
        margin-bottom: 40px;
      }

      &__item {
        background: var(--bgColor_lgray);
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 48px;
        position: relative;

        @include mi.mq(){
          width: 268px;
          font-size: 1.8rem;
          height: 48px;
        }

        @include mi.mq(sp){
          font-size: 1.6rem;
          height: 32px;
          padding: 0 16px;
          min-width: 96px;
        }

        &.is-active {
          background: black;
          color: white;
        }

        input {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    &__content {
      .p-card_list {
        @include mi.mq(){
          gap: 32px 48px;
        }

        @include mi.mq(sp){
          gap: 16px;
        }
      }

      .p-card.-event {
        @include mi.mq(){
          width: calc((100% - (48px * 3)) / 4);
        }
      }
    }
  }
}