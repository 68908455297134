@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-hansel {
  padding: 16px 24px;
  display: flex;
  justify-content: center;
  @include mi.font-secondary;

  @include mi.mq(sp){
    display: none;
  }

  &__body {
    display: flex;
    justify-content: center;

    li {


      + {
        li {
          display: flex;
          align-items: center;

          &::before {
            content: "＞";
            margin: 0 16px;
            color: var(--textColor_secondary);
          }
        }
      }

      a, mark {
        @include mi.mq(){
          font-size: 1.2rem;
          color: var(--textColor_secondary);
          line-height: 1.7;
        }
      }

      a {
        @include mi.mq(){
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}