@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-information_detail {
  @include mi.mq(){
    width: 1200px;
    margin: {
      top: 64px;
      right: auto;
      bottom: 64px;
      left: auto;
    }
    padding: {
      right: 64px;
      left: 64px;
    }
  }

  @include mi.mq(sp){
    padding: {
      top: 32px;
      bottom: 18px;
    }
  }

  &__header {
    display: flex;
    gap: 8px;
    align-items: baseline;
    justify-content: center;

    &::before {
      background: gray;
      border-radius: 50px;
      white-space: nowrap;
      flex-shrink: 0;

      @include mi.mq(){
        font-size: 1.6rem;
        padding: 4px 16px;
      }

      @include mi.mq(sp){
        font-size: 1.2rem;
        padding: 2px 8px;
        border-radius: 50px;
      }
    }

    &[data-press_release="true"]::before {
      content: "プレスリリース";
      background: var(--textColor_secondary);
      color: white;
    }

    .c-date {
      font-weight: bold;
      line-height: 1.5;

      @include mi.mq(){
        font-size: 1.4rem;
      }

      @include mi.mq(sp){
        font-size: 1.2rem;
      }
    }
  }

  .c-btn_wrapper {
    display: flex;

    @include mi.mq(pc){
      margin-top: 64px;
      gap: 20px;
      justify-content: center;
    }

    @include mi.mq(sp){
      margin-top: 32px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
  }

  &__free_space {
    @include mi.mq(){
      margin-top: 64px;
    }

    &:empty {
      display: none;
    }
  }
}