// *** Media Queries ***
$mq-pc-size : 768px;
$mq-sp-size : 769px;
$breakpoints: (
        'sp': 'screen and (max-width: #{$mq-sp-size})',
        'pc': 'print, screen and (min-width: #{$mq-pc-size})',
        'print' : 'print',
) !default;

// *** color ***
:root {
  --bgColor_lgray: rgba(244,244,244,1);
  --bgColor_shop_lineup: rgba(248,245,232,1);
  --bgColor_tab_header: rgba(231,231,231,1);
  --bgColor_btn_primary: rgba(234,234,234,1);
  --bgColor_header1: rgba(218,243,226,1);
  --bgColor_sp_nav: rgba(239,239,239,1);
  --textColor_primary: rgba(0,0,0,1);
  --textColor_secondary: rgba(119,119,119,1);
  --textColor_red: rgba(232,55,55,1);
  --textColor_green: rgba(54,131,81,1);
  --siteColor_main: rgba(107,178,132,1);
  --borderColor_date: rgba(152,152,152,1);
  --borderColor_table: rgba(179,179,179,1);
  --lBlue: rgba(176,234,236,1);
  --bgColor_slider_marker: rgba(217,217,217,1);
  --txtColor_placeholder: rgba(182,182,182,1);
  --textColor_link: rgba(8,102,255,1);
  --gradation_g: linear-gradient(160deg, rgba(239,248,232,1) 0%, rgba(229,247,242,1) 100%);


  --bgColor_card_shop: rgba(176,234,236,1);
  --bgColor_card_event: rgba(176,236,189,1);
  --bgColor_card_cooking: rgba(253,217,174,1);
  --bgColor_card_workshop: rgba(245,247,182,1);
  --bgColor_card_pop-up: rgba(245,209,250,1);
  --bgColor_card_food: rgba(255,192,192,1);
}
