@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-pick_up {
  @include mi.mq(){
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include mi.mq(sp){
    margin-top: 40px;
  }

  .c-heading {
    @include mi.mq(){
      margin-bottom: 16px;
    }

    @include mi.mq(sp){
      margin-bottom: 8px;
    }
  }

  .c-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include mi.mq(){
      width: 100%;
      max-width: 1512px;
      gap: 44px;
    }

    @include mi.mq(sp){
      padding: 0 26px;
      gap: 18px;
    }

    li {
      @include mi.mq(){
        width: 230px;
        height: 230px;
      }

      @include mi.mq(sp){
        width: calc((100% - 18px) / 2);
      }
    }

    a {
      aspect-ratio: 1 / 1;
      display: flex;

      @include mi.mq(){
        transition: .2s;

        &:hover {
          opacity: .7;
        }
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    }
  }
}