@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-global_footer {
  @include mi.mq() {
    gap: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    html[lang="en-US"] & {
      gap: 0;
    }
  }

  &__container {
    @include mi.mq() {
      width: 100%;
      height: 500px;
      background: {
        image: image-set(
            url("../img/cmn/bg_footer.png") 1x,
            url("../img/cmn/bg_footer@2x.png") 2x
        );
        size: cover;
        position: center;
      }
    }

    @include mi.mq(sp) {
      aspect-ratio: 392 / 466;
      background: {
        image: image-set(
            url("../img/cmn/bg_footer_sp.png") 1x,
            url("../img/cmn/bg_footer_sp@2x.png") 2x
        );
        size: cover;
        position: center;
      }
    }

    body[data-night_flag="true"] & {
      @include mi.mq() {
        background: {
          image: image-set(
              url("../img/cmn/bg_footer_night.png") 1x,
              url("../img/cmn/bg_footer_night@2x.png") 2x
          );
        }
      }

      @include mi.mq(sp) {
        background: {
          image: image-set(
              url("../img/cmn/bg_footer_night_sp.png") 1x,
              url("../img/cmn/bg_footer_night_sp@2x.png") 2x
          );
        }
      }
    }
  }

  &__info {
    @include mi.mq() {
      width: 100%;
      max-width: 1512px;
      padding: 56px;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: auto auto auto;
      gap: 8px 24px;
      grid-template-areas:
    "symbol follow_us"
    "address follow_us"
    "opening_hours follow_us";
    }

    @include mi.mq(sp) {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0 0;
    }

    html[lang="en-US"] & {
      @include mi.mq(){
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  &__symbol {
    @include mi.mq() {
      grid-area: symbol;
    }

    .c-figure {
      @include mi.mq() {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .c-symbol {
        @include mi.mq(sp) {
          width: calc(219 / 392 * 100vw);

          img {
            width: 100%;
            height: auto;
          }
        }
      }

      figcaption {
        color: white;
        font-weight: bold;

        @include mi.mq() {
          font-size: 1.8rem;
        }

        @include mi.mq(sp) {
          text-align: center;
          @include mi.mb_font_size(18);
          line-height: 2;
        }
      }
    }
  }

  &__address {
    @include mi.mq() {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    @include mi.mq(sp){
      width: 100%;
    }

    .c-address {
      color: white;
      font-weight: bold;

      @include mi.mq() {
        font-size: 1.4rem;
      }

      @include mi.mq(sp) {
        text-align: center;
        @include mi.mb_font_size(14);
        line-height: 2;
      }
    }

    .c-btn_wrapper {
      @include mi.mq(sp) {
        display: flex;
        justify-content: center;
        gap: 18px;
        margin-top: 10px;
      }

      li {
        @include mi.mq(sp){
          width: calc(150 / 392 * 100vw);
        }
      }
    }

    .c-btn {
      height: 26px;
      border-radius: 13px;
      font-weight: bold;
      color: white;
      grid-area: address;
      user-select: none;

      @include mi.mq() {
        font-size: 1.4rem;
        padding: 0 12px 0 14px;
        gap: 10px;
        border: 1px solid white;
        transition: .2s;

        &:hover {
          opacity: .7;
        }
      }

      @include mi.mq(sp) {
        @include mi.mb_font_size(14);
        padding: 0 8px;
        border: 2px solid white;
        width: 100%;
        white-space: nowrap;
      }

      &::before {
        content: "";
        background: {
          size: contain;
          position: center;
          repeat: no-repeat;
        }
        flex-shrink: 0;
      }

      &.-address {
        @include mi.mq(sp){
          gap: 8px;
        }

        &::before {
          width: 12px;
          height: 17px;
          background-image: url(../img/icn/icn_location_white.svg);
        }
      }

      &.-phone {
        @include mi.mq(sp){
          gap: 2px;
        }

        &::before {
          width: 14px;
          height: 15px;
          background-image: url(../img/icn/icn_phone_white.svg);
        }
      }
    }
  }

  &__opening_hours {
    @include mi.mq() {
      color: white;
      line-height: 2;
      grid-area: opening_hours;
    }

    .c-headline {
      @include mi.mq() {
        font-size: 1.6rem;
      }
    }

    small {
      @include mi.mq() {
        font-size: 1.4rem;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__follow_us {
    @include mi.mq() {
      grid-area: follow_us;
    }

    @include mi.mq(sp){
      margin-top: 10px;
    }

    &__container {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }

    .c-heading {
      line-height: 2;
      color: white;
      font-weight: bold;

      @include mi.mq() {
        font-size: 2rem;
      }

      @include mi.mq(sp) {
        @include mi.mb_font_size(20);
      }
    }

    .c-list {
      background: white;
      display: inline-flex;

      @include mi.mq() {
        border-radius: 26px;
        gap: 9px;
        padding: 11px 32px;
      }

      @include mi.mq(sp) {
        border-radius: 40px;
        gap: 16px;
        padding: 14px 32px;
      }

      a {
        @include mi.mq(){
          transition: .2s;

          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }

  &__nav {
    @include mi.mq(sp) {
      display: none;
    }

    .c-list {
      @include mi.mq() {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        justify-content: center;
      }
    }

    a {
      @include mi.mq() {
        line-height: 2;
        font-size: 1.6rem;
        font-weight: bold;
        word-break: keep-all;
        display: flex;
        position: relative;

        &::after {
          content: "";
          width: 0;
          height: 1px;
          background: var(--siteColor_main);
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          transition: .1s;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  &__copyright {
    display: flex;
    justify-content: center;
    font-weight: bold;

    @include mi.mq() {
      padding: 24px 0;
      font-size: 1.6rem;
    }

    @include mi.mq(sp){
      padding: 40px 0 96px;
      @include mi.mb_font_size(14);

      html[lang="en-US"] & {
        padding-top: 16px;
      }
    }
  }
}