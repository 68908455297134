@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-our_partners {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mi.mq(){
    margin-top: 80px;
  }

  @include mi.mq(sp){
    margin-top: 40px;
  }

  .c-heading {
    @include mi.mq(){
      margin-bottom: 16px;
    }

    @include mi.mq(sp){
      margin-bottom: 8px;
    }
  }

  .c-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include mi.mq(){
      width: 100%;
      max-width: 1512px;
      gap: 16px;
    }

    @include mi.mq(sp){
      margin: 0 24px;
      gap: 16px;
    }

    li {
      @include mi.mq(){
        width: 166px;
      }

      @include mi.mq(sp){
        width: calc((100% - 16px) / 2);
      }
    }

    a {
      display: flex;
      aspect-ratio: 332 / 116;

      @include mi.mq(){
        transition: .2s;

        &:hover {
          opacity: .7;
        }
      }
    }

    img {
      object-fit: cover;
      height: auto;
    }
  }
}