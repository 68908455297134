@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-shop_lineup {
  padding-top: 20px;
  position: relative;

  &::after {
    content: "";
    background-image: image-set(
        url("../img/cmn/bg_shop_lineup.png") 1x,
        url("../img/cmn/bg_shop_lineup@2x.png") 2x
    );
    background-size: cover;
    position: absolute;
    top: 3px;
    pointer-events: none;

    @include mi.mq(){
      width: 308px;
      height: 222px;
      right: -32px;
    }

    @include mi.mq(sp){
      width: calc(308 / 390 * 100vw);
      aspect-ratio: 308 / 222;
      right: -40px;
    }
  }

  &__inner {
    background: var(--bgColor_shop_lineup);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mi.mq(){
      padding: 35px 0 65px;
    }

    @include mi.mq(sp){
      padding: 14px 16px;
    }

    .c-heading {
      font-weight: bold;
      display: flex;
      align-items: center;
      line-height: 2;

      @include mi.mq(){
        font-size: 4rem;
      }

      @include mi.mq(sp){
        font-size: 3.2rem;
      }
    }
  }

  &__bnr {
    @include mi.mq(){
      width: 1024px;
      margin-top: 24px;
    }

    @include mi.mq(sp){
      margin: 10px;
    }

    .c-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include mi.mq(){
        gap: 24px;
      }

      @include mi.mq(sp){
        gap: 16px;
      }
    }

    li {
      @include mi.mq(){
        width: 166px;
      }

      @include mi.mq(sp){
        width: calc((100% - 24px) / 2);
      }
    }

    a {
      display: flex;
      aspect-ratio: 166 / 58;

      @include mi.mq(){
        transition: .2s;
        &:hover {
          opacity: .7;
        }
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;

      @include mi.mq(){
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .p-tab {
    @include mi.mq(){
      margin-top: 40px;
      width: 1024px;
    }

    @include mi.mq(sp){
      padding-top: 10px;
      width: 100%;
    }

    &__header {
      display: flex;
      justify-content: center;

      @include mi.mq(){
        gap: 8px;
      }

      @include mi.mq(sp){
        gap: 4px;
      }

      &__item {
        background: var(--bgColor_tab_header);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        border-radius: 6px 6px 0 0;

        @include mi.mq(){
          height: 52px;
          width: 216px;
          font-size: 1.6rem;
          transition: .2s;
        }

        @include mi.mq(sp){
          text-align: center;
          @include mi.mb_font_size(12);
          line-height: 1.4;
          width: 100%;
          word-break: keep-all;
          padding: 8px 0;
        }

        &.is-active,
        &:hover {
          background: white;
        }
      }
    }

    &__content {
      margin-top: -1px;
      background: white;

      @include mi.mq(){
        padding: 35px 70px;
      }

      @include mi.mq(sp){
        padding: 16px;
      }

      .c-list {
        display: flex;
        flex-wrap: wrap;

        @include mi.mq(){
          gap: 4px;
        }

        @include mi.mq(sp){
          gap: 8px;
        }

        li {
          @include mi.mq(){
            width: 144px;
          }

          @include mi.mq(sp){
            width: calc((100% - 16px) / 3);
          }
        }

        a {
          aspect-ratio: 420 / 200;
          display: flex;

          @include mi.mq(){
            transition: .2s;

            &:hover {
              opacity: .7;
            }
          }
        }

        img {
          object-fit: cover;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}