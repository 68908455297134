@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-global_header {
  @include mi.mq(){
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto auto;
    gap: 6px 0;
    grid-template-areas:
    "search search"
    "symbol global_nav";
    width: 100%;
    max-width: 1400px;
  }

  @include mi.mq(sp){
    flex: 1;
    display: flex;
    align-items: center;
  }

  &__symbol {
    display: flex;

    @include mi.mq(){
      grid-area: symbol;
      gap: 7px;
    }

    @include mi.mq(sp){
      gap: 6px;

      img {
        height: 45px;
        width: auto;
      }
    }

    a {
      display: flex;

      @include mi.mq(){
        gap: 7px;
      }

      @include mi.mq(sp){
        gap: 6px;
      }
    }
  }
}