@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-profile {
  border: 1px solid black;

  @include mi.mq(){
    display: grid;
    grid-template-columns: 240px auto 1fr;
    grid-template-rows: auto auto 1fr;
    //gap: 4px 40px;
    gap: 4px 16px;
    grid-template-areas:
    "image title title"
    "image organization name"
    "image description description";
    padding: 24px 72px 24px 24px;
  }

  @include mi.mq(sp){
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 10px;
  }

  .c-img {
    grid-area: image;

    @include mi.mq(sp){
      order: 2;
      padding-bottom: 18px;
    }

    img {
      height: auto;
      width: 208px;
    }
  }

  .c-tle {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2;

    @include mi.mq(){
      grid-area: title;
      padding-bottom: 14px;
    }

    @include mi.mq(sp){
      order: 1;
      padding-bottom: 18px;
    }
  }

  .c-organization {
    font-weight: bold;
    line-height: 2;

    @include mi.mq(){
      grid-area: organization;
      font-size: 1.6rem;
    }

    @include mi.mq(sp){
      order: 3;
      font-size: 1.4rem;
    }
  }

  .c-name {
    font-weight: bold;
    line-height: 2;

    @include mi.mq(){
      grid-area: name;
      font-size: 1.6rem;
    }

    @include mi.mq(sp){
      order: 4;
      font-size: 1.4rem;
      margin-bottom: 18px;
    }
  }

  .c-desc {
    grid-area: description;
    font-size: 1.3rem;
    line-height: 1.8;

    @include mi.mq(sp){
      order: 5;
    }

    a {
      text-decoration: underline;

      @include mi.mq(){
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}