@use "../extension/mixin" as mi;
@use "../extension/variables" as *;

.l-header {
  display: flex;

  @include mi.mq(){
    padding: 16px 24px;
    justify-content: center;
  }

  @include mi.mq(sp){
    height: 64px;
    padding: 0 14px;
    align-items: center;
    position: relative;
    z-index: 100;
  }
}
