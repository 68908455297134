@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-notification {

  li {
    margin: 8px 0;

    &:last-child {
      @include mi.mq(){
        margin-bottom: 16px;
      }
    }
  }

  a {
    display: block;
    border: 1px solid var(--textColor_red);
    color: var(--textColor_red);
    font-weight: bold;

    @include mi.mq(){
      padding: 4px;
      font-size: 1.6rem;
      text-align: center;
      line-height: 1.6;
      width:1024px;
      margin-right: auto;
      margin-left: auto;
    }

    @include mi.mq(sp){
      padding: 5px;
      font-size: 1.4rem;
    }
  }
}