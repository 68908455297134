@use "../extension/variables" as *;
@use "../extension/mixin" as mi;

html {
  font-size: 62.5%;
  margin-top: 0 !important;
}

html, body {
  width: 100%;
}

body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: inherit;
  font-stretch: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  color: inherit;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: none;

  * {
    box-sizing: border-box;

    &::before,
    &::after {
      box-sizing: border-box;
    }
  }
}

sup {
  font-size: 0.8em;
  vertical-align: super;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-spacing: 0;
}

a {
  text-decoration: none;
}

img {
  vertical-align: top;
}