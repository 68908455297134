@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.wpcf7 {
  h3 {
    + {
      p {
        @include mi.font-secondary;
        font-weight: normal;

        @include mi.mq(){
          margin-top: 16px;
        }

        @include mi.mq(sp){
          margin-top: 8px;
        }
      }
    }

    .c-required {
      margin-left: 10px;
      color: var(--textColor_red);

      @include mi.mq(){
        font-size: 1.4rem;
      }

      @include mi.mq(sp){
        font-size: 1.2rem;
      }
    }
  }

  p {
    + {
      h3 {
        @include mi.mq(sp){
          margin-top: 32px;
        }
      }
    }
  }

  input[type="text"],
  input[type="email"]{
    line-height: 1.6;
    appearance: none;
    background: var(--bgColor_lgray);
    border: none;
    @include mi.font-secondary;

    @include mi.mq(){
      width: 300px;
      height: 77px;
      font-size: 1.8rem;
      padding: 24px;
      border-radius: 8px;
    }

    @include mi.mq(sp){
      width: 100%;
      max-width: 300px;
      height: 58px;
      font-size: 1.6rem;
      padding: 0 16px;
      border-radius: 4px;
    }

    &::placeholder {
      color: var(--txtColor_placeholder);
    }
  }

  // radio
  .wpcf7-list-item {
    margin-left: 0;
    margin-right: 32px;
    display: inline-flex;
    align-items: center;
    @include mi.font-secondary;

    .wpcf7-list-item-label {
      display: flex;
      align-items: center;

      @include mi.mq(sp){
        font-size: 1.8rem;
        line-height: 1.6;
      }

      &::before {
        width: 30px;
        height: 30px;
        background: #000;
        margin-right: 16px;
        border-radius: 50%;
        border: 15px solid var(--bgColor_lgray);
      }
    }

    input {
      @include mi.input_invisible;

      &:checked {
        ~ .wpcf7-list-item-label::before {
          border: 9px solid var(--bgColor_lgray);
        }
      }
    }
  }

  select {
    line-height: 1.6;
    appearance: none;
    background: var(--bgColor_lgray);
    border: none;
    @include mi.font-secondary;

    @include mi.mq() {
      width: 300px;
      height: 77px;
      font-size: 1.8rem;
      padding: 24px;
      border-radius: 8px;
    }

    @include mi.mq(sp) {
      width: 100%;
      max-width: 300px;
      height: 58px;
      font-size: 1.8rem;
      padding: 0 16px;
      border-radius: 8px;
    }
  }

  textarea {
    width: 100%;
    font-size: 1.8rem;
    line-height: 1.6;
    padding: 24px;
    appearance: none;
    background: var(--bgColor_lgray);
    border-radius: 8px;
    border: none;
    @include mi.font-secondary;

    &[rows="10"] {
      height: 300px;
    }

  }

  .c-btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;

    @include mi.mq(){
      margin: {
        top: 24px;
      }
    }

    @include mi.mq(sp){
      margin: {
        top: 16px;
      }
    }
  }

  input.c-btn.-submit {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    line-height: 2;
    padding: 8px 48px 8px 24px;
    border-radius: 100px;
    gap: 8px;
    font-weight: bold;
    background: {
      color: var(--bgColor_btn_primary);
      image: url(../img/icn/icn_arrow_r.svg);
      repeat: no-repeat;
      position: right 24px center;
    }
    box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
  }

  // エラーメッセージ
  .wpcf7-form-control-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    @include mi.font-secondary;
  }

  .wpcf7-not-valid-tip {
    display: inline-flex;
    background: var(--textColor_red);
    color: white;
    font-size: 1.8rem;
    line-height: 1.6;
    padding: 0 8px;
    @include mi.font-secondary;
  }

  .wpcf7-response-output {
    text-align: center;
    border: none !important;
    font-size: 1.8rem;
    line-height: 1.6;
    color: var(--textColor_red);
    margin: 10px 0 0 !important;
    padding: 0 !important;
    @include mi.font-secondary;
  }

  .wpcf7-spinner {
    position: absolute;
    margin: 0;
  }
}