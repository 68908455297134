@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-hamburger {
  width: 56px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  @include mi.mq(){
    display: none;
  }

  .c-patty {
    width: mi.sp_size(24);
    height: mi.sp_size(24);
    position: relative;

    &::before,
    &::after {
      content: "";
      height: 1px;
      background: #000;
      position: absolute;
      top: 0;
      right: -2px;
      bottom: 0;
      margin: auto;
    }

    &::before {
      transform: translateY(-2px);
      left: -8px;
    }

    &::after {
      transform: translateY(6px);
      left: 0;
    }
  }

  .c-label {
    font-weight: bold;
    line-height: 1.5;
    word-break: keep-all;
    @include mi.mb_font_size(12);
  }
}