@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-search_result {
  .c-list {
    display: flex;
    flex-direction: column;
    @include mi.font-secondary;

    li {
      line-height: 1.8;

      @include mi.mq(){
        font-size: 1.8rem;
      }

      @include mi.mq(sp){
        font-size: 1.4rem;
      }

      + li {
        @include mi.mq() {
          margin-top: 24px;

        }

        @include mi.mq(sp) {
          margin-top: 16px;
        }
      }

      a {
        text-decoration: underline;

        @include mi.mq(){
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}