@use "../extension/mixin" as mi;
@use "../extension/variables" as *;

.l-wrapper {
  overflow: hidden;
  max-width: 100%;

  @include mi.mq(){
    min-width: 1152px;
  }
}

.l-main {
  @include mi.mq(sp){
    padding: 0 16px;
  }
}
