@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-floor_guide {
  @include mi.mq(){
    width: 100%;
    max-width: 1400px;
    margin: 64px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @include mi.mq(sp){
    margin-top: 32px;
  }

  &__header {
    .c-list {
      display: flex;

      @include mi.mq(){
        gap: 24px;
        width: 1104px;
      }

      @include mi.mq(sp){
        gap: 16px;
        flex-direction: column;
        align-items: center;
      }

      li {
        @include mi.mq(){
          width: 100%;
        }

        @include mi.mq(sp){
          width: 270px;
        }

        a {
          background: var(--bgColor_lgray);
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1.5;
          font-weight: bold;
          border-radius: 48px;

          @include mi.mq(){
            height: 48px;
            font-size: 2.2rem;
          }

          @include mi.mq(sp){
            height: 30px;
            font-size: 1.4rem;
          }

          &.is-current {
            background: var(--textColor_primary);
            color: white;
            pointer-events: none;
          }
        }
      }
    }
  }

  &__tab {
    @include mi.mq(){
      width: 100%;
      margin-top: 40px;
    }

    @include mi.mq(sp){
      margin-top: 32px;
    }

    .p-tab__header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @include mi.mq(){
        gap: 24px;
        margin-bottom: 64px;
      }

      @include mi.mq(sp){
        gap: 16px;
        margin-bottom: 32px;
        flex-wrap: wrap;
      }

      &__item {
        background: var(--bgColor_lgray);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        border-radius: 56px;

        @include mi.mq(){
          width: 56px;
          height: 56px;
          font-size: 2.4rem;
        }

        @include mi.mq(sp){
          width: calc((100% - (4 * 16px)) / 5);
          aspect-ratio: 1 /1;
          font-size: 1.6rem;
        }

        &.is-active {
          background: var(--textColor_primary);
          color: white;
        }
      }

      &.-floor_map {
        @include mi.mq(){
          margin-bottom: 40px;
        }
      }

      &.-flex {
        gap: 8px;

        @include mi.mq(){
          width: 1104px;
          margin: {
            right: auto;
            left: auto;
          }
        }

        .p-tab__header__item {
          @include mi.mq(){
            width: auto;
            font-size: 1.8rem;
            padding: 0 24px;
            height: 44px;
          }

          @include mi.mq(sp){
            width: auto;
            aspect-ratio: auto;
            font-size: 1.6rem;
            line-height: 1.5;
            padding: 4px 16px;
          }
        }
      }
    }
  }
}