@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-event_detail {
  @include mi.mq(){
    max-width: 1400px;
    min-width: 1152px;
    margin: {
      right: auto;
      left: auto;
      bottom: 64px;
    }
    padding: {
      top: 64px;
      right: 64px;
      left: 64px;
    }
  }

  @include mi.mq(sp){
    padding: {
      top: 32px;
    }
  }

  &__header {
    display: flex;
    gap: 8px;
    align-items: baseline;

    @include mi.mq(){
      justify-content: center;
    }

    &::before {
      background: gray;
      border-radius: 50px;
      white-space: nowrap;
      flex-shrink: 0;

      @include mi.mq(){
        font-size: 1.6rem;
        padding: 4px 16px;
      }

      @include mi.mq(sp){
        font-size: 1.2rem;
        padding: 2px 8px;
        border-radius: 50px;
      }
    }

    &[data-event-type="event"]::before {
      content: "EVENT";
      background: var(--bgColor_card_event);
    }

    &[data-event-type="shop"]::before {
      content: "SHOP NEWS";
      background: var(--bgColor_card_shop);
    }

    &[data-event-type="cooking"]::before {
      content: "COOKING";
      background: var(--bgColor_card_cooking);
    }

    &[data-event-type="workshop"]::before {
      content: "WORKSHOP";
      background: var(--bgColor_card_workshop);
    }

    &[data-event-type="pop-up"]::before {
      content: "POP-UP";
      background: var(--bgColor_card_pop-up);
    }

    &[data-event-type="food"]::before {
      content: "食品催事";
      background: var(--bgColor_card_food);
    }


    .c-place {
      font-weight: bold;
      line-height: 1.5;

      @include mi.mq(){
        font-size: 1.6rem;
      }

      @include mi.mq(sp){
        font-size: 1.2rem;
      }
    }

    a.c-place {
      text-decoration: underline;

      @include mi.mq(){
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__main {
    @include mi.mq(){
      display: flex;
      gap: 64px;
    }
  }

  &__img {
    @include mi.mq(){
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;

      @include mi.mq(){
        border-radius: 24px;
      }

      @include mi.mq(sp){
        border-radius: 16px;
      }
    }
  }

  &__container {
    @include mi.mq(){
      width: 100%;
    }
  }

  &__main_info {
    @include mi.mq(){
      padding-bottom: 32px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }

    @include mi.mq(sp){
      margin-top: 32px;
    }

    .c-detail {
      display: flex;
      flex-direction: column;

      @include mi.mq(){
        gap: 8px;
      }

      @include mi.mq(sp){
        gap: 4px;
      }

      .c-scope {
        display: flex;
        align-items: baseline;

        @include mi.mq(){
          gap: 8px;
        }

        @include mi.mq(sp){
          gap: 4px;
        }

        dt {
          background: #000;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;
          line-height: 1;
          font-weight: bold;
          flex-shrink: 0;

          @include mi.mq(){
            width: 96px;
            padding: 4px;
            font-size: 1.6rem;
          }

          @include mi.mq(sp){
            width: 64px;
            padding: 2px;
            font-size: 1.2rem;
          }
        }

        dd {
          font-weight: bold;

          @include mi.mq(){
            font-size: 1.6rem;
            line-height: 1.6875;
          }

          @include mi.mq(sp){
            font-size: 1.4rem;
            line-height: 1.5;
            transform: translateY(1px);
          }

          .c-date {
            @include mi.mq(){
              font-size: 2.2rem;
            }

            @include mi.mq(sp){
              font-size: 1.8rem;
            }

            small {
              @include mi.mq(){
                font-size: 1.6rem;
              }
              @include mi.mq(sp){
                font-size: 1.2rem;
              }
            }
          }

          a.c-place {
            text-decoration: underline;

            @include mi.mq(){
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  &__description {
    @include mi.mq(sp){
      margin-top: 24px;
    }

    p {
      line-height: 1.8;

      @include mi.mq(){
        font-size: 1.8rem;
      }

      @include mi.mq(sp){
        font-size: 1.4rem;
      }
    }

    .c-lead {
      font-weight: bold;

      @include mi.mq(){
        font-size: 2.2rem;
        line-height: 1.7;
      }

      @include mi.mq(sp){
        font-size: 1.6rem;
        line-height: 1.5;
      }

      + {
        p {
          @include mi.mq(){
            margin-top: 32px;
          }

          @include mi.mq(sp){
            margin-top: 24px;
          }
        }
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        @include mi.mq(){
          text-decoration: none;
        }
      }
    }
  }

  &__sub_info {
    @include mi.mq(){
    }

    @include mi.mq(sp){
      margin-top: 24px;
    }

    .c-detail {
      display: flex;
      flex-direction: column;

      .c-scope {
        border-bottom: 1px dotted var(--textColor_secondary);
        display: flex;
        align-items: flex-start;
        padding: 0 0 15px;

        + .c-scope {
          padding: 15px 0;
        }

        dt {
          width: 80px;
          line-height: 1.6;
          font-weight: bold;
          flex-shrink: 0;

          @include mi.mq(){
            font-size: 1.6rem;
          }

          @include mi.mq(sp){
            @include mi.mb_font_size(14);
            line-height: 1.6;
          }
        }

        dd {
          @include mi.font-secondary;
          @include mi.mq(){
            font-size: 1.6rem;
            line-height: 1.5;
          }

          @include mi.mq(sp){
            @include mi.mb_font_size(14);
            line-height: 1.6;
          }

          a {
            text-decoration: underline;
            word-break: break-all;

            @include mi.mq(){
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .c-social {
      display: flex;
      align-items: center;
      gap: 30px;

      a {
        @include mi.mq(){
          transition: .2s;

          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }

  &__out_of_parking_service {
    @include mi.mq(){
      margin-top: 4px;
      padding: 16px 0;
    }

    @include mi.mq(sp){
      margin-top: 2px;
      padding: 8px 0;
    }

    .c-label {
      display: inline-block;
      color: var(--textColor_red);
      font-weight: bold;
      border: 1px solid var(--textColor_red);
      padding: 4px 8px;

      @include mi.mq(){
        font-size: 1.6rem;
      }
    }
  }

  .c-btn_wrapper {
    display: flex;
    justify-content: center;

    @include mi.mq(pc){
      margin-top: 64px;
    }

    @include mi.mq(sp){
      margin-top: 32px;
    }
  }

  &__free_space {
    @include mi.mq(){
      margin-top: 64px;
    }

    &:empty {
      display: none;
    }
  }

  &__closed {
    color: var(--textColor_red);
    border: 1px solid;
    padding: 4px;
    line-height: 1.6;
    font-weight: bold;
    text-align: center;

    @include mi.mq(){
      font-size: 1.8rem;
    }

    @include mi.mq(sp){
      font-size: 1.4rem;
    }
  }
}