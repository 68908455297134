@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-recruit {
  @include mi.mq(){
    margin: 64px auto;
    width: 1024px;
  }

  @include mi.mq(sp){
    margin: 32px 0 0;
  }

  &__header {
    @include mi.mq(){
      margin-bottom: 64px;
    }

    @include mi.mq(sp){
      margin-bottom: 32px;
    }

    .c-lead {
      font-weight: bold;
      line-height: 1.6;

      @include mi.mq(){
        font-size: 1.8rem;
        text-align: center;
      }

      @include mi.mq(sp){
        font-size: 1.4rem;
      }
    }
  }

  &__item {
    background: var(--bgColor_lgray);

    @include mi.mq(){
      border-radius: 24px;
      padding: 48px;
    }

    @include mi.mq(sp){
      border-radius: 16px;
      padding: 16px;
    }

    + .p-recruit__item {
      @include mi.mq(){
        margin-top: 64px;
      }

      @include mi.mq(sp){
        margin-top: 32px;
      }
    }

    &__header {
      background: white;
      display: flex;
      border-radius: 100px;
      font-weight: bold;

      @include mi.mq(){
        margin-bottom: 24px;
        padding: 16px;
        gap: 36px;
        align-items: center;
      }

      @include mi.mq(sp){
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 24px;
        gap: 4px;
        margin-bottom: 24px;
      }

      dt {
        background: #000;
        color: white;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include mi.mq(){
          height: 36px;
          padding: 0 16px;
          font-size: 1.8rem;
        }

        @include mi.mq(sp){
          font-size: 1.2rem;
          padding: 0 8px;
          height: 24px;
        }
      }

      dd {
        line-height: 1.6;

        @include mi.mq(){
          font-size: 2.2rem;
        }

        @include mi.mq(sp){
          font-size: 1.8rem;
        }

        a {
          text-decoration: underline;

          @include mi.mq(){
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &__detail {
      @include mi.mq(){
        padding: 0 32px;
      }

      .c-copy {
        font-weight: bold;
        line-height: 1.6;

        @include mi.mq(){
          margin-bottom: 32px;
          font-size: 1.8rem;
        }

        @include mi.mq(sp){
          font-size: 1.4rem;
          margin-bottom: 24px;
        }
      }

      .c-list {
        display: flex;
        flex-direction: column;
      }

      .c-scope {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px dashed var(--textColor_secondary);
        line-height: 1.6;
        padding-bottom: 15px;

        @include mi.mq(){
          font-size: 1.6rem;
          gap: 8px;
        }

        @include mi.mq(sp){
          font-size: 1.4rem;
          gap: 4px;
        }

        + .c-scope {
          padding-top: 15px;
        }

        dt {
          font-weight: bold;
          flex-shrink: 0;
          width: 80px;
        }

        dd {
          @include mi.font-secondary;

          a {
            text-decoration: underline;

            @include mi.mq(){
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}