@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.c-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;

  &.-primary {
    background: var(--bgColor_btn_primary);
    line-height: 2;
    border-radius: 100px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

    @include mi.mq(){
      padding: 8px 24px;
      font-size: 20px;
    }

    @include mi.mq(sp){
      padding: 4px 16px;
      @include mi.mb_font_size(18);
    }

    &:hover {
      @include mi.mq(){
        transition: .2s;

        &:hover {
          opacity: .7;
        }
      }
    }

    .c-icn {
      display: inline-flex;
      align-items: center;
      margin-left: 9px;
    }
  }
}