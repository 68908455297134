@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-press_release {
  @include mi.mq(){
    width: 1024px;
    margin: {
      top: 64px;
      right: auto;
      bottom: 64px;
      left: auto;
    }
  }

  @include mi.mq(sp){
    margin: {
      top: 32px;
      bottom: 32px;
    }
  }

  &__header {
    @include mi.mq(){
      margin-bottom: 64px;
    }

    .c-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include mi.mq(){
        gap: 24px;
      }

      @include mi.mq(sp){
        gap: 4px;
      }

      a {
        display: inline-flex;
        background: var(--bgColor_lgray);
        font-weight: bold;
        align-items: center;
        justify-content: center;
        border-radius: 48px;

        @include mi.mq(){
          font-size: 2.2rem;
          height: 48px;
          padding: 0 24px;
        }

        @include mi.mq(sp){
          font-size: 1.6rem;
          height: 32px;
          padding: 0 16px;
        }

        &.is-current {
          background: #000;
          color: white;
        }
      }
    }
  }


  .c-btn_wrapper {
    display: flex;

    @include mi.mq(pc){
      margin-top: 64px;
      gap: 20px;
      justify-content: center;
    }

    @include mi.mq(sp){
      margin-top: 32px;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
  }
}