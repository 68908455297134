@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-language_switcher {height: 28px;
  display: flex;
  align-items: center;

  a {
    font-size: 1.4rem;
    font-weight: bold;
  }
}