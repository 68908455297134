@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.c-heading {
  &.-page_title {
    background: var(--gradation_g);
    font-weight: bold;
    line-height: 1.5;
    display: flex;
    flex-direction: column;

    @include mi.mq(){
      padding: 32px 16px;
      font-size: 3.2rem;
      text-align: center;
      gap: 16px;
    }

    @include mi.mq(sp){
      padding: 24px 16px;
      gap: 8px;
      @include mi.mb_font_size(24);
      margin: {
        right: -16px;
        left: -16px;
      }
    }
  }

  &.-lv1 {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    font-weight: bold;
    min-height: 70px;

    @include mi.mq(){
      font-size: 4rem;
    }

    @include mi.mq(sp){
      font-size: 3.2rem;
    }

    &::before {
      content: "";
      width: 70px;
      height: 70px;
      border-radius: 100%;
      background: var(--bgColor_header1);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: -1;
    }
  }
}