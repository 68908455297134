@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-page {
  > * {
    + {
      * {
        @include mi.mq() {
          margin-top: 24px;
        }

        @include mi.mq(sp) {
          margin-top: 16px;
        }
      }

      h2,
      .p-card_list,
      .p-staff_info {
        @include mi.mq() {
          margin-top: 64px;
        }

        @include mi.mq(sp){
          margin-top: 32px;
        }
      }

      h3,
      .wp-block-columns,
      .wp-block-image {
        @include mi.mq() {
          margin-top: 48px;
        }

        @include mi.mq(sp){
          margin-top: 28px;
        }
      }

      h4 {
        @include mi.mq() {
          margin-top: 40px;
        }

        @include mi.mq(sp){
          margin-top: 24px;
        }
      }
    }
  }

  @include mi.mq() {
    width: 1200px;
    margin: {
      top: 64px;
      right: auto;
      bottom: 64px;
      left: auto;
    }
    padding: {
      right: 64px;
      left: 64px;
    }
  }

  @include mi.mq(sp) {
    padding: {
      top: 32px;
      bottom: 18px;
    }
  }

  h2 {
    background: var(--gradation_g);
    line-height: 1.6;
    font-weight: bold;
    padding: 6px 16px;

    @include mi.mq() {
      font-size: 2.4rem;
    }

    @include mi.mq(sp){
      font-size: 1.8rem;

    }

    + {
      h3 {
        @include mi.mq() {
          margin-top: 40px;
        }

        @include mi.mq(sp){
          margin-top: 16px;
        }
      }

      p {
        @include mi.mq() {
          margin-top: 32px;
        }

        @include mi.mq(sp){
          margin-top: 16px;
        }
      }
    }
  }

  h3 {
    font-weight: bold;
    position: relative;
    line-height: 1.6;

    @include mi.mq() {
      font-size: 2.2rem;
      padding-left: 33px;
    }

    @include mi.mq(sp){
      font-size: 1.8rem;
      padding-left: 28px;
    }

    &::before {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 6px;

      @include mi.mq(){
        width: 23px;
        height: 23px;
        border: 7px solid var(--siteColor_main);
      }

      @include mi.mq(sp){
        width: 18px;
        height: 18px;
        border: 5px solid var(--siteColor_main);
      }
    }

    + {
      h4,
      p {
        @include mi.mq() {
          margin-top: 24px;
        }

        @include mi.mq(sp){
          margin-top: 16px;
        }
      }
    }
  }

  h4 {
    color: var(--siteColor_main);
    font-weight: bold;
    line-height: 1.6;

    @include mi.mq() {
      font-size: 2rem;
    }

    @include mi.mq(sp) {
      font-size: 1.6rem;
    }

    + {
      p {
        @include mi.mq() {
          margin-top: 16px;
        }

        @include mi.mq(sp) {
          margin-top: 8px;
        }
      }
    }
  }

  p {
    font-weight: bold;
    line-height: 1.8;

    @include mi.mq() {
      font-size: 1.8rem;
    }

    @include mi.mq(sp){
      font-size: 1.4rem;
    }

    + {
      h3 {
        @include mi.mq(){
          margin-top: 40px;
        }

        @include mi.mq(sp){
          margin-top: 20px;
        }
      }
    }
  }

  ul {
    &.wp-block-list {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.6;
      gap: 8px;

      @include mi.mq() {
        font-size: 1.8rem;
      }

      @include mi.mq(sp){
        font-size: 1.4rem;
      }

      li {
        position: relative;

        @include mi.mq() {
          padding-left: 24px;
        }

        @include mi.mq(sp){
          padding-left: 16px;
        }

        &::before {
          content: "";
          border-radius: 14px;
          background: var(--siteColor_main);
          position: absolute;
          left: 0;

          @include mi.mq(){
            width: 14px;
            height: 14px;
            top: 8px;
          }

          @include mi.mq(sp){
            width: 10px;
            height: 10px;
            top: 6px;
          }
        }
      }
    }

    &.c-list.-annotation {
      font-weight: normal;
      display: flex;
      flex-direction: column;
      position: relative;
      line-height: 1.6;
      @include mi.font-secondary;

      @include mi.mq() {
        font-size: 1.6rem;
        gap: 4px;
        margin-top: 8px;
      }

      @include mi.mq(sp){
        font-size: 1.2rem;
        gap: 2px;
        margin-top: 4px;
      }

      li {
        padding-left: 1.2em;

        &::before {
          content: "※";
          position: absolute;
          left: 0;
        }
      }
    }
  }

  .wp-block-table {
    @include mi.mq(sp) {
      overflow: auto;
    }

    table {
      min-width: 100%;
      border-collapse: collapse;

      th, td {
        border: {
          top: 1px solid var(--borderColor_table);
          bottom: 1px solid var(--borderColor_table);
        }
        font-weight: bold;
        vertical-align: middle;
        line-height: 2;

        @include mi.mq() {
          padding: 16px;
          font-size: 1.8rem;
        }

        @include mi.mq(sp){
          font-size: 1.4rem;
          padding: 4px;
        }

        + {
          th, td {
            border: {
              left: 1px solid var(--borderColor_table);
            }
          }
        }
      }

      th {
        background: var(--bgColor_lgray);
        white-space: nowrap;
      }

      thead,
      tfoot{
        th, td {
          background: var(--bgColor_tab_header);

          + {
            th, td {
              border: {
                left: 1px solid white;
              }
            }
          }
        }
      }
    }
  }

  .wp-block-buttons {
    .wp-block-button {
      a {
        background: var(--bgColor_btn_primary);
        font-weight: bold;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .15);
        line-height: 2;

        @include mi.mq(){
          font-size: 2rem;
          padding: 8px 24px;
        }

        @include mi.mq(sp){
          font-size: 1.6rem;
          padding: 4px 16px;
        }

        &::after {
          content: "";
          width: 16px;
          height: 16px;
          background: {
            image: url(../img/icn/icn_arrow_r.svg);
            repeat: no-repeat;
          }
        }

        &:hover {
          @include mi.mq(){
            transition: .2s;

            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  }

  .wp-block-columns {
    display: flex;

    @include mi.mq(){
      gap: 40px;
      flex-wrap: nowrap;
    }

    @include mi.mq(sp){
      gap: 24px;
      flex-direction: column;
    }

    + {
      .wp-block-columns {
        @include mi.mq(){
          margin-top: 40px;
        }
      }
    }

    .wp-block-column {
      margin-top: 0;
      width: 100%;
    }
  }

  .wp-block-image {
    &.size-full {
      img {
        width: 100%;
        height: auto;
      }
    }

    .alignleft {
      text-align: left;

      img {
        max-width: 100%;
        height: auto;
      }
    }
    .aligncenter {
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    figcaption {
      font-weight: bold;
      margin-top: 10px;
      line-height: 1.6;

      @include mi.mq(){
        font-size: 1.6rem;
      }

      @include mi.mq(sp){
        font-size: 1.4rem;
      }
    }
  }
}