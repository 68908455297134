@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-recommend_bnr {
  display: flex;

  @include mi.mq(){
    padding: 32px 0;
    justify-content: center;
  }

  @include mi.mq(sp){
    //margin: 0 16px;
    padding: 32px 14px;
  }

  .c-list {
    display: flex;

    @include mi.mq() {
      width: 1272px;
      justify-content: center;
      gap: 48px;
    }

    @include mi.mq(sp){
      flex-direction: column;
      gap: 16px;
    }

    li {
      @include mi.mq(){
        width: 480px;
      }
    }

    a {
      aspect-ratio: 960 / 540;
      display: flex;
      overflow: hidden;

      @include mi.mq(){
        transition: .2s;
        border-radius: 8px;

        &:hover {
          opacity: .7;
        }
      }

      @include mi.mq(sp){
        border-radius: 6px;
      }
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
}