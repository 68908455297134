@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-shop_list {
  .c-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;

    @include mi.mq(){
      gap: 40px;
      width: 100%;
      padding: 0 64px;
    }

    @include mi.mq(sp){
      flex-direction: column;
      gap: 8px;
    }

    li {
      @include mi.mq(){
        width: calc((100% - (40px * 3)) / 4);
      }
    }
  }
}