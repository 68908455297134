@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-global_utility {
  @include mi.mq(){
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 22px;
    grid-area: search;
  }

  @include mi.mq(sp){
    margin-left: auto;
  }
}