@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-tab {
  &__content {
    display: none;

    &.is-show {
      display: block;
    }
  }

  &__header {
    &__item {
      @include mi.mq(){
        cursor: pointer;

        &.is-active {
          pointer-events: none;
        }
      }
    }
  }
}