@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

._pc {
  @include mi.mq(sp){
    display: none !important;
  }
}

._sp {
  @include mi.mq(){
    display: none !important;
  }
}

._jp {
  html[lang="en-US"] & {
    display: none;
  }
}

._en {
  html[lang="ja"] & {
    display: none;
  }
}