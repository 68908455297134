@use "../../extension/mixin" as mi;
@use "../../extension/variables" as *;

.p-gutenberg {

  p {
    line-height: 1.6;
    font-weight: bold;

    @include mi.mq(){
      font-size: 1.8rem;
    }

    @include mi.mq(sp){
      font-size: 1.6rem;
    }

    + {
      p {
        margin-top: 1.6em;
      }
    }

    a {
      text-decoration: underline;

      @include mi.mq(sp){
        color: var(--textColor_link);
      }

      @include mi.mq(){
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}